import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import "./EarthLogo.css";
import "../../App.css";
function EarthLogo() {
  return (
    <a className="rigHeteroLogo"  onClick={() =>
      window.open(
        "https://dimenzia.heterometa.com",
        "_blank",
        "noopener,noreferrer"
      )
    }>
      <img src="/earth2.0(icon)-edited.png" alt="Rotate"></img>
    </a>
  );
}

export default EarthLogo