import "./App.css";
import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { HeteroVideo } from "./components/HeteroVideo";
import { Discover } from "./components/Discover";
import { WhatIsHeterometa } from "./components/DiscoverPages/WhatIsHeterometa";
import { Design } from "./components/DiscoverPages/Design";
import { WhyHeteroMeta } from "./components/DiscoverPages/WhyHeterometa";
import { Contact } from "./components/Contact";
import { Contact2 } from "./components/Contact2";
import { Partners } from "./components/Partners";
import { Particles } from "./components/Particles";
import { Team } from "./components/Team";
import { Brands } from "./components/Brands";
import { Privacy } from "./components/BottomPages/Privacy";
import { TermsOfUse } from "./components/BottomPages/TermsOfUse";
import "bootstrap/dist/css/bootstrap.min.css";
import {AnimatePresence , motion } from "framer-motion"
import EarthLogo from "./components/EarthLogo/EarthLogo";
import ExploreButton from "./components/Widgets/ExploreButton/ExploreButton";
import { Welcome } from "./components/Welcome";
import { Map1 } from "./components/Marketplace/map_1";

const Animated = () => {
  const location = useLocation();
  return (
    // New commit for test
    <Routes location={location} key={location.pathname}>
      <Route path="home" element={<HeteroVideo />}></Route>
      <Route path="discover" element={<Discover />}></Route>
      <Route path="contact" element={<Contact2 />}></Route>
      <Route path="partners" element={<Partners />}></Route>
      <Route path="team" element={<Team />}></Route>
      <Route path="map1" element={<Map1></Map1>}></Route>
      <Route path="brandups" element={<Brands />}></Route>
      <Route path="privacy" element={<Privacy />}></Route>
      <Route path="terms-of-use" element={<TermsOfUse />}></Route>
      <Route path="discover/what-is-heterometa" element={<WhatIsHeterometa />}></Route>
      <Route path="discover/design" element={<Design />}></Route>
      <Route path="discover/why-heterometa" element={<WhyHeteroMeta />}></Route>
      <Route path="/" element={<Welcome />}></Route>
    </Routes>
  );
};
function App() {
  return (
    <>
      <Router>
        <Animated />
      </Router>
    </>
  );
}

export default App;
