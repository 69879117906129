import ".././App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../Transition";
import firebaseConfig from "../firebaseConfig.js";
import { ref, getStorage, getDownloadURL, listAll } from "firebase/storage";
import { Vector3 } from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, SpotLight, useDepthBuffer } from "@react-three/drei";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeteroLogo from "./HeteroLogo";

function Scene() {
  // This is a super cheap depth buffer that only renders once (frames: 1 is optional!), which works well for static scenes
  // Spots can optionally use that for realism, learn about soft particles here: http://john-chapman-graphics.blogspot.com/2013/01/good-enough-volumetrics-for-spotlights.html
  // teeest
  const depthBuffer = useDepthBuffer({ frames: 1 });
  const { nodes, materials } = useGLTF("./model/frapp.gltf");
  return (
    <>
      <MovingSpot
        depthBuffer={depthBuffer}
        color="#0c8cbf"
        position={[3, 3, 3]}
      />
      <MovingSpot
        depthBuffer={depthBuffer}
        color="#b00c3f"
        position={[1, 3, 2]}
      />
      <mesh
        position={[0, -0.1, 1.5]}
        castShadow
        receiveShadow
        geometry={nodes.Text.geometry}
        material={materials["Metal 04"]}
        dispose={null}
        scale={0.035}
        rotation-x={-30}
        rotation-z={0.0}
      />
      <mesh receiveShadow position={[0, -1, 3]} rotation-x={-Math.PI / 2}>
        <planeGeometry args={[50, 50]} />
        <meshPhongMaterial />
      </mesh>
    </>
  );
}

function MovingSpot({ vec = new Vector3(), ...props }) {
  const light = useRef();
  const viewport = useThree((state) => state.viewport);
  useFrame((state) => {
    light.current.target.position.lerp(
      vec.set(
        (state.mouse.x * viewport.width) / 2,
        (state.mouse.y * viewport.height) / 2,
        0
      ),
      0.1
    );
    light.current.target.updateMatrixWorld();
  });
  return (
    <SpotLight
      castShadow
      ref={light}
      penumbra={1.2}
      distance={8}
      angle={0.35}
      attenuation={5}
      anglePower={4}
      intensity={2}
      {...props}
    />
  );
}
function Partners() {
  // -- Burger Menu -- \\
  const [logos, setLogos] = useState([]);
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });

  const getLogos = async () => {
    const storage = getStorage();
    const reference = ref(storage, "/markalar/otmotiv");
    listAll(reference)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {});
        res.items.forEach((itemRef) => {
          console.log(itemRef);
          getDownloadURL(itemRef).then((x) => {
            setUrl(x);
            setLogos((current) => [...current, x]);
          });
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getLogos();
  }, []);

  function handeGetImages() {}

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <>
                <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Partners
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <Canvas
                    className="canvasPartners"
                    shadows
                    dpr={[1, 2]}
                    camera={{
                      position: [-2, 2, 6],
                      fov: 50,
                      near: 1,
                      far: 20,
                    }}
                  >
                    <color attach="background" args={["#202020"]} />
                    <fog attach="fog" args={["#202020", 5, 20]} />
                    <ambientLight intensity={0.015} />
                    <Scene />
                  </Canvas>
                  {/* 3 Cards */}
                  {/* <Carousel
                    showArrows={false}
                    showStatus={false}
                    className="canvasPartners"
                    emulateTouch={true}
                    showIndicators={open ? false : true}
                  >
                    <div>
                      <Canvas
                        className="canvasPartners"
                        shadows
                        dpr={[1, 2]}
                        camera={{
                          position: [-2, 2, 6],
                          fov: 50,
                          near: 1,
                          far: 20,
                        }}
                      >
                        <color attach="background" args={["#202020"]} />
                        <fog attach="fog" args={["#202020", 5, 20]} />
                        <ambientLight intensity={0.015} />
                        <Scene />
                      </Canvas>
                      <p className="legend">Legend 1</p>
                    </div>
                    <div>
                      <Canvas
                        className="canvasPartners"
                        shadows
                        dpr={[1, 2]}
                        camera={{
                          position: [-2, 2, 6],
                          fov: 50,
                          near: 1,
                          far: 20,
                        }}
                      >
                        <color attach="background" args={["#202020"]} />
                        <fog attach="fog" args={["#202020", 5, 20]} />
                        <ambientLight intensity={0.015} />
                        <Scene />
                      </Canvas>
                      <p className="legend">Legend 2</p>
                    </div>
                    <div>
                      <Canvas
                        className="canvasPartners"
                        shadows
                        dpr={[1, 2]}
                        camera={{
                          position: [-2, 2, 6],
                          fov: 50,
                          near: 1,
                          far: 20,
                        }}
                      >
                        <color attach="background" args={["#202020"]} />
                        <fog attach="fog" args={["#202020", 5, 20]} />
                        <ambientLight intensity={0.015} />
                        <Scene />
                      </Canvas>
                      <p className="legend">Legend 3</p>
                    </div>
                    <div>
                      <Canvas
                        className="canvasPartners"
                        shadows
                        dpr={[1, 2]}
                        camera={{
                          position: [-2, 2, 6],
                          fov: 50,
                          near: 1,
                          far: 20,
                        }}
                      >
                        <color attach="background" args={["#202020"]} />
                        <fog attach="fog" args={["#202020", 5, 20]} />
                        <ambientLight intensity={0.015} />
                        <Scene />
                      </Canvas>
                      <p className="legend">Legend 4</p>
                    </div>
                    <div>
                      <Canvas
                        className="canvasPartners"
                        shadows
                        dpr={[1, 2]}
                        camera={{
                          position: [-2, 2, 6],
                          fov: 50,
                          near: 1,
                          far: 20,
                        }}
                      >
                        <color attach="background" args={["#202020"]} />
                        <fog attach="fog" args={["#202020", 5, 20]} />
                        <ambientLight intensity={0.015} />
                        <Scene />
                      </Canvas>
                      <p className="legend">Legend 5</p>
                    </div>
                  </Carousel> */}
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <>
                <HeteroLogo/>
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Partners
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Worldwide Partners, trusting the future of HeteroMeta.
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                </Container>
                <Canvas
                  className="canvasPartners"
                  shadows
                  dpr={[1, 2]}
                  camera={{
                    position: [-2, 2, 6],
                    fov: 50,
                    near: 1,
                    far: 20,
                  }}
                >
                  <color attach="background" args={["#202020"]} />
                  <fog attach="fog" args={["#202020", 5, 20]} />
                  <ambientLight intensity={0.015} />
                  <Scene />
                </Canvas>
                {/* <Carousel
                  showArrows={false}
                  showStatus={false}
                  className="canvasPartners"
                  emulateTouch={true}
                  showIndicators={open ? false : true}
                >
                  <div>
                    <Canvas
                      className="canvasPartners"
                      shadows
                      dpr={[1, 2]}
                      camera={{
                        position: [-2, 2, 6],
                        fov: 50,
                        near: 1,
                        far: 20,
                      }}
                    >
                      <color attach="background" args={["#202020"]} />
                      <fog attach="fog" args={["#202020", 5, 20]} />
                      <ambientLight intensity={0.015} />
                      <Scene />
                    </Canvas>
                    <p className="legend">Legend 1</p>
                  </div>
                  <div>
                    <Canvas
                      className="canvasPartners"
                      shadows
                      dpr={[1, 2]}
                      camera={{
                        position: [-2, 2, 6],
                        fov: 50,
                        near: 1,
                        far: 20,
                      }}
                    >
                      <color attach="background" args={["#202020"]} />
                      <fog attach="fog" args={["#202020", 5, 20]} />
                      <ambientLight intensity={0.015} />
                      <Scene />
                    </Canvas>
                    <p className="legend">Legend 2</p>
                  </div>
                  <div>
                    <Canvas
                      className="canvasPartners"
                      shadows
                      dpr={[1, 2]}
                      camera={{
                        position: [-2, 2, 6],
                        fov: 50,
                        near: 1,
                        far: 20,
                      }}
                    >
                      <color attach="background" args={["#202020"]} />
                      <fog attach="fog" args={["#202020", 5, 20]} />
                      <ambientLight intensity={0.015} />
                      <Scene />
                    </Canvas>
                    <p className="legend">Legend 3</p>
                  </div>
                  <div>
                    <Canvas
                      className="canvasPartners"
                      shadows
                      dpr={[1, 2]}
                      camera={{
                        position: [-2, 2, 6],
                        fov: 50,
                        near: 1,
                        far: 20,
                      }}
                    >
                      <color attach="background" args={["#202020"]} />
                      <fog attach="fog" args={["#202020", 5, 20]} />
                      <ambientLight intensity={0.015} />
                      <Scene />
                    </Canvas>
                    <p className="legend">Legend 4</p>
                  </div>
                  <div>
                    <Canvas
                      className="canvasPartners"
                      shadows
                      dpr={[1, 2]}
                      camera={{
                        position: [-2, 2, 6],
                        fov: 50,
                        near: 1,
                        far: 20,
                      }}
                    >
                      <color attach="background" args={["#202020"]} />
                      <fog attach="fog" args={["#202020", 5, 20]} />
                      <ambientLight intensity={0.015} />
                      <Scene />
                    </Canvas>
                    <p className="legend">Legend 5</p>
                  </div>
                </Carousel> */}
                {/* <div class="partners-container">
                  {logos.map((element, index) => {
                    return (
                      <div class="partner-card-container" key={index}>
                        <a href="">
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div> */}
                <Container>
                  <div class="firefly"></div>
                  <div div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { Partners };
