import React, { useEffect, useState, useRef } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import "./Footer.css";
const FooterPage = () => {
  useEffect(() => {
    console.clear();
    /* Js */
    let aphorisms = [
      "Are you ready to break out of this cycle in the world?",
      "Do you need a technology.",
      "or its worth?",
      "Get rid off the waste of your brand ",
      "bring up the values and ideas!",
    ];

    let data = {
      aphorisms: aphorisms,
    };

    function BlipText(
      el,
      copyArray,
      flickerSpeed = 50,
      holdDelay = 2000,
      noise = "-*.°"
    ) {
      this.contentArray = copyArray;
      this.contentCount = 0;
      this.charCount = 0;
      this.domElement = el;
      this.flickerSpeed = flickerSpeed;
      this.holdDelay = holdDelay;
      this.noise = noise.split("");
      this.noiseCount = 0;
      this.compose(this.contentArray[this.contentCount++]);
    }
    BlipText.prototype.compose = function (txt) {
      this.noiseCount = 0;
      this.charCount = 0;
      this.flickerUp(txt);
    };
    BlipText.prototype.decompose = function () {
      this.noiseCount = 0;
      this.charCount = 0;
      this.flickerDown();
    };
    BlipText.prototype.flickerUp = function (txt) {
      let t = txt.substr(0, this.charCount);
      this.domElement.textContent = t + this.noise[this.noiseCount++];
      if (this.noiseCount < this.noise.length) {
        setTimeout(() => this.flickerUp(txt), this.flickerSpeed);
      } else if (this.charCount++ < txt.length - 1) {
        this.noiseCount = 0;
        setTimeout(() => this.flickerUp(txt), this.flickerSpeed);
      } else {
        this.domElement.textContent = txt;
        setTimeout(() => this.decompose(), this.holdDelay);
      }
    };
    BlipText.prototype.flickerDown = function () {
      let t = this.domElement.textContent.slice(0, -1);
      this.domElement.textContent = t + this.noise[this.noiseCount++];
      if (this.noiseCount < this.noise.length) {
        setTimeout(() => this.flickerDown(), this.flickerSpeed);
      } else if (t.length > 0) {
        this.noiseCount = 0;
        this.domElement.textContent = this.domElement.textContent.slice(0, -1);
        setTimeout(() => this.flickerDown(), this.flickerSpeed);
      } else {
        this.domElement.textContent = "";
        this.compose(this.contentArray[this.contentCount++]);
        if (this.contentCount >= this.contentArray.length) {
          this.contentCount = 0;
        }
      }
    };

    let blipTargets = [...document.querySelectorAll(".blip-text")];
    for (let i = 0; i < blipTargets.length; i++) {
      let dataSet = blipTargets[i].dataset;
      let blipper = new BlipText(
        blipTargets[i],
        data[dataSet.src],
        Number(dataSet.flicker),
        Number(dataSet.hold),
        dataSet.noise
      );
    }

    /* ---------------------------------- */
  }, []);

  return (
    <>
      <MobileView>
        <div>
          <footer class="site-footer">
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div className="disclaimerContent">
                  <h6>DISCLAIMER</h6>
                  <p class="text-justify">
                    No Investment AdviceThe information provided on this website
                    does not constitute investment advice, financial advice,
                    trading advice, or any other sort of advice and you should
                    not treat any of the website's content as such.{" "}
                    <i>HeteroMeta</i> does not recommend that any cryptocurrency
                    should be bought, sold, or held by you. Do conduct your own
                    due diligence and consult your financial advisor before
                    making any investment decisions
                  </p>
                </div>
              </div>
            </div>
            <div className="">
              <div class="row">
                <div class="col">
                  {" "}
                  <ul class="footer-links">
                    <li>
                      <a
                        onClick={() =>
                          window.open(
                            "https://dimenzia.heterometa.com",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      >
                        <h5>EARTH 2.0</h5>
                      </a>
                    </li>
                    <li>
                      <a href="/brandups">
                        <h5>BRANDUPS</h5>
                      </a>
                    </li>
                    <li>
                      <a href="/partners">
                        <h5>PARTNERS</h5>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="col">
                  {" "}
                  <ul class="footer-links">
                    <li>
                      <a
                        href=""
                        onClick={() =>
                          window.open(
                            "https://litepaper.heterometa.com",
                            "_blank",
                            "noopener,noreferrer"
                          )
                        }
                      >
                        <h5>LITEPAPER</h5>
                      </a>
                    </li>
                    <li>
                      <a href="/team">
                        <h5>TEAM</h5>
                      </a>
                    </li>
                    <li>
                      <a href="/contact">
                        <h5>CONTACT</h5>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr></hr>
            <br></br>
            <div class="row">
              <div class="col-sm-4">
                <p
                  class="blip-text"
                  data-src="aphorisms"
                  data-flicker="10"
                  data-hold="1200"
                  data-noise="-.*°"
                ></p>
              </div>
              <div class="col-sm-6">
                <div className="termsFaqPrivacy">
                  <h7>
                    <a className="bottomSheetText" href="/terms-of-use">
                      Terms Of Use
                    </a>
                  </h7>
                  <h7>
                    <a className="bottomSheetText" href="/privacy">
                      Privacy
                    </a>
                  </h7>

                  <h7>
                    <a className="bottomSheetText" href="#">
                      FAQ
                    </a>
                  </h7>

                  <h7>
                    <a className="bottomSheetText" href="#">
                      LICENSE
                    </a>
                  </h7>

                  <a>
                    <h7>
                      <a className="bottomSheetText" href="#">
                        NFT PURCHASE AGREEMENT
                      </a>
                    </h7>
                  </a>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="flex-center">
                <button
                  onClick={() =>
                    window.open(
                      "https://discord.gg/WuvmMPh5rY ",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="discord-button"
                  className="social-button"
                >
                  <i class="fa-brands fa-discord fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://twitter.com/HeteroMeta",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="twitter-button"
                  className="social-button"
                >
                  <i class="fa fa-twitter fa-1x icon-3d"></i>
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/heterometa/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="instagram-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-instagram fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCUhu_lKvaf5PCOmrD0Oq2tw",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="youtube-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-youtube fa-1x icon-3d"></i>{" "}
                </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </MobileView>
      <BrowserView>
        <div>
          <footer class="site-footer">
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="firefly"></div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div className="disclaimerContent">
                  <h6>DISCLAIMER</h6>
                  <p class="text-justify">
                    No Investment AdviceThe information provided on this website
                    does not constitute investment advice, financial advice,
                    trading advice, or any other sort of advice and you should
                    not treat any of the website's content as such.{" "}
                    <i>HeteroMeta</i> does not recommend that any cryptocurrency
                    should be bought, sold, or held by you. Do conduct your own
                    due diligence and consult your financial advisor before
                    making any investment decisions
                  </p>
                </div>
              </div>
              <div class="col-xs-6 col-md-2">
                <br></br>
              </div>

              <div class="col-xs-6 col-md-2">
                <br></br>
                <ul class="footer-links">
                  <li>
                    <a
                      onClick={() =>
                        window.open(
                          "https://dimenzia.heterometa.com",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      <h5>EARTH 2.0</h5>
                    </a>
                  </li>
                  <li>
                    <a href="/brandups">
                      <h5>BRANDUPS</h5>
                    </a>
                  </li>
                  <li>
                    <a href="/partners">
                      <h5>PARTNERS</h5>
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-xs-6 col-md-2">
                <br></br>
                <ul class="footer-links">
                  <li>
                    <a
                      href="#"
                      onClick={() =>
                        window.open(
                          "https://litepaper.heterometa.com",
                          "_blank",
                          "noopener,noreferrer"
                        )
                      }
                    >
                      {" "}
                      <h5>LITEPAPER</h5>
                    </a>
                  </li>
                  <li>
                    <a href="/team">
                      <h5>TEAM</h5>
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <h5>CONTACT</h5>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <hr></hr>
            <div class="row">
              <div class="col-sm-4">
                <p
                  class="blip-text"
                  data-src="aphorisms"
                  data-flicker="10"
                  data-hold="1200"
                  data-noise="-.*°"
                ></p>
              </div>
              <div class="col-sm-6">
                <div className="termsFaqPrivacy">
                  <h7>
                    <a className="bottomSheetText" href="/terms-of-use">
                      Terms Of Use
                    </a>
                  </h7>
                  <h7>
                    <a className="bottomSheetText" href="/privacy">
                      Privacy
                    </a>
                  </h7>

                  <h7>
                    <a className="bottomSheetText" href="/privacy">
                      FAQ
                    </a>
                  </h7>

                  <h7>
                    <a className="bottomSheetText" href="#">
                      LICENSE
                    </a>
                  </h7>

                  <a>
                    <h7>
                      <a className="bottomSheetText" href="#">
                        NFT PURCHASE AGREEMENT
                      </a>
                    </h7>
                  </a>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="flex-center">
                <button
                  onClick={() =>
                    window.open(
                      "https://discord.gg/WuvmMPh5rY ",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="discord-button"
                  className="social-button"
                >
                  <i class="fa-brands fa-discord fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://twitter.com/HeteroMeta",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="twitter-button"
                  className="social-button"
                >
                  <i class="fa fa-twitter fa-1x icon-3d"></i>
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/heterometa/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="instagram-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-instagram fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCUhu_lKvaf5PCOmrD0Oq2tw",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="youtube-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-youtube fa-1x icon-3d"></i>{" "}
                </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </BrowserView>
    </>
  );
};

export default FooterPage;
