import ".././App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../Transition";
import HeteroLogo from "./HeteroLogo";

function Discover() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 1500);
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <>
                <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Discover
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* 3 Cards */}
                  <a className="carda" href="/discover/what-is-heterometa">
                    <div class="card text-white bg-transparent mb-3 cardPadding">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_01_dune(mobile).png?alt=media&token=f6f3a2e9-7dff-4bad-92d1-e0c975fed036"
                        alt="Card image cap"
                      ></img>
                      <div class="card-body">
                        <h5 class="card-title cardTitle">What is HeteroMeta</h5>
                        <p class="card-text cardContent">
                          ‘’ the world was a beautiful place… ‘’ The HeteroMeta
                          is a metaverse of Andromeda that provides a universe
                          to ‘’create’’, ‘’share’’ and ‘’win’’ together...
                        </p>
                      </div>
                    </div>
                  </a>
                  <a className="carda" href="/discover/why-heterometa">
                    <div class="card text-white bg-transparent mb-3 cardPadding">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_02_tunnel(mobile).png?alt=media&token=eb4bdf1e-8ba9-419f-8d93-2db464334651"
                        alt="Card image cap"
                      ></img>
                      <div class="card-body">
                        <h5 class="card-title cardTitle">Why HeteroMeta</h5>
                        <p class="card-text cardContent">
                          Note from the whitepaper, ‘’Why do we buy something?
                          What is it we need? Is it the function of the product?
                          Maybe. But is that the only factor? No...
                        </p>
                        {/* <p class="card-text">
      <a class="readMore">Read More</a>
    </p> */}
                      </div>
                    </div>
                  </a>
                  <a className="carda" href="/discover/design">
                    <div class="card text-white bg-transparent mb-3 cardPadding">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_03_earth2.0(mobile).png?alt=media&token=6bb6e6bd-f703-40d8-813c-5324ce139486"
                        alt="Card image cap"
                      ></img>
                      <div class="card-body">
                        <h5 class="card-title cardTitle">Design</h5>
                        <p class="card-text cardContent">
                          Every Dimenzia leads you to the endless
                          posibilities....
                        </p>
                        {/* <p class="card-text">
      <a class="readMore">Read More</a>
    </p> */}
                      </div>
                    </div>
                  </a>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <>
                =
                <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Discover
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        The latest updates from Andromeda and beyond, the
                        HeteroMeta
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* 3 Cards */}

                  <div class="card text-white bg-transparent mb-3 cardPadding">
                    <a href="/discover/what-is-heterometa">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fdiscover_01_dune.jpg?alt=media&token=176a35c7-6bb1-4454-8a0d-f708bbf4b175"
                        alt="Card image cap"
                      ></img>
                    </a>
                    <div class="card-body">
                      <h5 class="card-title cardTitle">What is HeteroMeta</h5>
                      <p class="card-text cardContent">
                        ‘’ the world was a beautiful place… ‘’ The HeteroMeta is
                        a metaverse of Andromeda that provides a universe to
                        ‘’create’’, ‘’share’’ and ‘’win’’ together...
                      </p>
                      {/* <p class="card-text">
      <a class="readMore">Read More</a>
    </p> */}
                    </div>
                  </div>

                  <div class="card text-white bg-transparent mb-3 cardPadding">
                    <a href="/discover/why-heterometa">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fdiscover_02_tunnel.jpg?alt=media&token=b4c0b74f-965b-47e5-93cf-da54fa5dea17"
                        alt="Card image cap"
                      ></img>
                    </a>
                    <div class="card-body">
                      <h5 class="card-title cardTitle">
                        <a href="/discover/why-heterometa" class="cardTitle">
                          Why HeteroMeta
                        </a>
                      </h5>
                      <p class="card-text cardContent">
                        Note from the whitepaper, ‘’Why do we buy something?
                        What is it we need? Is it the function of the product?
                        Maybe. But is that the only factor? No...
                      </p>
                      {/* <p class="card-text">
      <a class="readMore">Read More</a>
    </p> */}
                    </div>
                  </div>

                  <div class="card text-white bg-transparent mb-3 cardPadding">
                    <a href="/discover/design">
                      <img
                        className="cardImage"
                        src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fdiscover_03_earth2.0.jpg?alt=media&token=66266087-da65-44aa-a2cd-4d9b3c26968b"
                        alt="Card image cap"
                      ></img>
                    </a>
                    <div class="card-body">
                      <h5 class="cardTitle">
                        <a href="/discover/design" class="cardTitle">
                          Design
                        </a>
                      </h5>
                      <p class="card-text cardContent">
                        Every Dimenzia leads you to the endless posibilities...
                      </p>

                      {/* <p class="card-text">
      <a class="readMore">Read More</a>
    </p> */}
                    </div>
                  </div>

                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { Discover };
