import "../../App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../../Transition";
import { ref, getStorage, getDownloadURL, listAll } from "firebase/storage";

function TermsOfUse() {
  // -- Burger Menu -- \\
  const [logos, setLogos] = useState([]);
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <Container className="container">
                {/* Title */}
                <p className="titleText">
                  PRIVACY POLICY
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                </p>
                {/* <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                </h1> */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* 3 Cards */}

                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <Container className="container">
                {/* Title */}

                {/* <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                </h1> */}
                <br></br>
                <div class="card text-white bg-transparent mb-3 cardPadding">
                  {/* <img
                    className="cardImage"
                    src="/images/discover_01_dune.jpg"
                    alt="Card image cap"
                  ></img> */}
                  <div class="card-body bottomSheetCard">
                    <h5 class="card-title cardTitle"> </h5>
                    <p class="card-text bottomSheetContent">
                      <p className="titleText">
                        Terms Of Use
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                      </p>
                      <p>
                        Please read these Terms of Use (the “Terms”) and our
                        Privacy Policy (“Privacy Policy”) carefully because they
                        govern your use of the website located at{" "}
                        <a href="https://www.heterometa.com/privacy">
                          https://www.heterometa.com/privacy
                        </a>{" "}
                        and the content and functionalities accessible via the
                        Site (collectively, the “Site”) offered by Heterometa.
                      </p>
                      <p>
                        IMPORTANT NOTICE REGARDING ARBITRATION: WHEN YOU AGREE
                        TO THESE TERMS YOU ARE AGREEING (WITH LIMITED EXCEPTION)
                        TO RESOLVE ANY DISPUTE BETWEEN YOU AND HETEROMETA
                        THROUGH BINDING, INDIVIDUAL ARBITRATION RATHER THAN IN
                        COURT. PLEASE REVIEW CAREFULLY SECTION 14 “DISPUTE
                        RESOLUTION” BELOW FOR DETAILS REGARDING ARBITRATION.
                        HOWEVER, IF YOU ARE A RESIDENT OF A JURISDICTION WHERE
                        APPLICABLE LAW PROHIBITS ARBITRATION OF DISPUTES, THE
                        AGREEMENT TO ARBITRATE IN SECTION ‎14 WILL NOT APPLY TO
                        YOU BUT THE PROVISIONS OF SECTION 13 (GOVERNING LAW AND
                        FORUM CHOICE) WILL STILL APPLY.
                      </p>
                      <p>
                        1. Agreement to Terms. By using our Site, you agree to
                        be bound by these Terms. If you do not want to be bound
                        by these Terms, do not use the Site.
                      </p>
                      <p>
                        2. Privacy Policy. Please review our Privacy Policy,
                        which also governs your use of the Site, for information
                        on how we collect, use and share your information.
                      </p>
                      <p>
                        3. Changes to these Terms or the Site. We may update the
                        Terms from time to time in our sole discretion. If we
                        do, we will let you know by posting the updated Terms on
                        the Site. It is important that you review the Terms
                        whenever we update them or you use the Site. If you
                        continue to use the Site after we have posted updated
                        Terms, it means that you accept and agree to the
                        changes. If you do not agree to be bound by the changes,
                        you may not use the Site anymore. We may change or
                        discontinue all or any part of the Site at any time, and
                        without notice, at our sole discretion.
                      </p>
                      <p>
                        4. Who May Use the Site? You may use the Site only if
                        you are at least 18 years old, capable of forming a
                        binding contract with HeteroMeta, and not otherwise
                        barred from using the Site under applicable law.
                      </p>
                      <p>
                        5. Feedback. We appreciate feedback, comments, ideas,
                        proposals and suggestions for improvements to the Site
                        (“Feedback”). If you choose to submit Feedback, you
                        agree that we are free to use it without any restriction
                        or compensation to y
                      </p>
                      <p>
                        6. HeteroMeta’ Intellectual Property. We may make
                        available through the Site content that is subject to
                        intellectual property rights. We or our licensors, or
                        the third parties who otherwise own the intellectual
                        property rights, retain all rights to that content.
                      </p>
                      <p>
                        7. General Prohibitions and HeteroMeta’ Enforcement
                        Right
                      </p>
                      <p>
                        (a) Use, display, mirror, or frame in any way the Site
                        or any individual element within the Site, HeteroMeta’
                        name, any HeteroMeta trademark, logo, or other
                        proprietary information, or the layout and design of any
                        page or form contained on a page, without HeteroMeta’
                        express written consent;
                        <p>
                          (b) Access, tamper with, or use in any way non-public
                          areas of the Site, HeteroMeta&rsquo; computer systems,
                          or the technical delivery systems of HeteroMeta&rsquo;
                          providers;
                          <br />
                          (c) Attempt to or actually probe, scan, or test in any
                          way the vulnerability of (or lack of vulnerability of)
                          any system or network (collectively,
                          &ldquo;Systems&rdquo;), or breach any security or
                          authentication measures, available or accessible
                          through, or related to, the Site;
                          <br />
                          (d) Avoid, bypass, remove, deactivate, impair,
                          descramble, or otherwise circumvent, in any way, any
                          measure implemented by HeteroMeta or any of
                          HeteroMeta&rsquo; providers or any other third party
                          (including another user) to protect the Site or any
                          element thereof of any;
                          <br />
                          (e) Attempt to or actually access or search the Site,
                          or download content (or any element thereof) from the
                          Site, using any engine, software, tool, agent, device,
                          or other mechanism (including spiders, robots,
                          crawlers, data mining tools or the like) or modality
                          other than the software and/or search agents expressly
                          provided by HeteroMeta or other generally available
                          third-party web browsers;
                          <br />
                          (f) Access or use the Site, or any portion or element
                          thereof, for any commercial purpose or for the benefit
                          of any third party, or in any other manner not
                          permitted by these Terms;
                          <br />
                          (g) Attempt to or actually decipher, decompile,
                          disassemble, or reverse engineer any of the software
                          used to provide the Site, or any element thereof, or
                          any of the Systems;
                          <br />
                          (h) Interfere with, or attempt to interfere with, the
                          access of any user, host or network, including,
                          without limitation, sending a virus, overloading,
                          flooding, spamming, or mail-bombing the Site or any
                          Systems;
                          <br />
                          (i) Impersonate or misrepresent your affiliation with
                          any person or entity, including but not limited to
                          HeteroMeta or any representative or agent thereof;
                          <br />
                          (j) Violate any applicable law or regulation; or
                          <br />
                          (k) Directly or indirectly encourage or enable any
                          other individual to do any of the foregoing.
                          <br />
                        </p>
                        <p>
                          HeteroMeta is not obligated to monitor access to or
                          use of the Site or to review or edit any Site content.
                          However, we have the right to do so for the purpose of
                          operating the Site, to ensure compliance with these
                          Terms, and to comply with applicable law or other
                          legal requirements. We reserve the right, but are not
                          obligated, to remove or disable access to the Site (or
                          any element or content therein or thereof), at any
                          time and without notice, including, but not limited
                          to, if we, at our sole discretion, consider it
                          objectionable or in violation of these Terms. We have
                          the right to investigate actual and suspected
                          violations of these Terms and other conduct that
                          affects the Site. We may also consult and cooperate
                          with law enforcement authorities to prosecute users
                          who violate the law.
                        </p>
                        <p>
                          8. Links to Third Party Websites or Resources. The
                          Site may allow you to access third-party websites or
                          other resources. We provide access only as a
                          convenience and are not responsible for the content,
                          products, or services on or available from those
                          resources or links displayed on such websites. You
                          acknowledge that you have sole responsibility for, and
                          assume all risk arising from, your use of any
                          third-party resources.
                        </p>
                        <p>
                          9. Termination. We may suspend or terminate your
                          access to and use of the Site, at our sole discretion,
                          at any time and without notice to you. Upon any
                          termination or discontinuation of the Site or these
                          Terms, the following Sections will survive: 5, 6, 8,
                          9, 10, 11, 12, 13, 14, and 15.
                        </p>
                        <p>10. Warranty Disclaimers.</p>
                        <p>
                          (a) THE SITE IS PROVIDED &ldquo;AS IS,&rdquo; WITHOUT
                          WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING,
                          WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF
                          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                          QUIET ENJOYMENT, AND NON-INFRINGEMENT, AND ANY
                          WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE
                          OF TRADE. We make no warranty that the Site will meet
                          your requirements or be available on an uninterrupted,
                          secure, or error-free basis. We make no warranty
                          regarding the quality, accuracy, timeliness,
                          truthfulness, completeness, or reliability of any
                          information or content on the Site. Any reliance you
                          place on such information or content is strictly at
                          your own risk.
                          <br />
                          (b) To the extent the Site enables you to interact
                          with any blockchain protocol (each, a
                          &ldquo;Protocol&rdquo;), you understand that your use
                          of any such Protocol is entirely at your own risk. Any
                          such Protocol is available on an &ldquo;as is&rdquo;
                          basis without warranties of any kind, either express
                          or implied, including, but not limited to, warranties
                          of merchantability, fitness for a particular purpose,
                          quiet enjoyment, and non-infringement. You assume all
                          risks associated with using any Protocol, and digital
                          assets and decentralized systems generally, including
                          but not limited to, that digital assets are highly
                          volatile; you may not have ready access to assets; and
                          you may lose some or all of your tokens or other
                          assets. You agree that you will have no recourse
                          against HeteroMeta for any losses due to your use of
                          any Protocol. For example, these losses may arise from
                          or relate to: (i) lost funds; (ii) server failure or
                          data loss; (iii) corrupted cryptocurrency wallet
                          files; (iv) unauthorized access; (v) errors, mistakes,
                          or inaccuracies; or (vi) third-party activities.
                          <br /> <br />
                          11. Indemnity.
                          <br />
                          <br />
                          You will indemnify and hold HeteroMeta and its
                          affiliates and their respective officers, directors,
                          employees, and agents, harmless from and against any
                          claims, disputes, demands, liabilities, damages,
                          losses, costs, and expenses, including, without
                          limitation, reasonable legal and accounting fees
                          arising out of or in any way related to (a) your
                          access to or use of the Site, or (b) your violation of
                          these Terms.
                          <br />
                          <br />
                          12. Limitation of Liability.
                          <br />
                          <br />
                          (a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER
                          HETEROMETA NOR ITS SERVICE PROVIDERS INVOLVED IN
                          CREATING, PRODUCING, OR DELIVERING THE SITE WILL BE
                          LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR
                          CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS,
                          LOST REVENUES, LOST SAVINGS, LOST BUSINESS
                          OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE
                          INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE, OR
                          THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING
                          OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE
                          USE OF OR INABILITY TO USE THE SITE, WHETHER BASED ON
                          WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE),
                          PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND
                          WHETHER OR NOT HETEROMETA OR ITS SERVICE PROVIDERS
                          HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE,
                          EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO
                          HAVE FAILED OF ITS ESSENTIAL PURPOSE.
                          <br />
                          (b) TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO
                          EVENT WILL HETEROMETA&rsquo; TOTAL LIABILITY ARISING
                          OUT OF OR IN CONNECTION WITH THESE TERMS, OR FROM THE
                          USE OF OR INABILITY TO USE THE SITE, EXCEED ONE
                          HUNDRED U.S. DOLLARS ($100).
                          <br />
                          (c) BY USING THE SITE, YOU ACKNOWLEDGE THAT THE
                          EXCLUSIONS AND LIMITATIONS OF DAMAGES SET FORTH ABOVE
                          ARE MATERIAL ELEMENTS OF THE BASIS OF THE BARGAIN
                          BETWEEN HETEROMETA AND YOU.
                          <br />
                          13. Governing Law and Forum Choice.&#160;These Terms,
                          and any action related thereto, will be governed by
                          the U.S. Federal Arbitration Act, federal arbitration
                          law, and the laws of the State of New York, without
                          regard to its conflict of laws provisions. Except as
                          otherwise expressly set forth in Section 14
                          &ldquo;Dispute Resolution,&rdquo; the exclusive
                          jurisdiction for all Disputes (defined below) will be
                          the state and federal courts located in the State and
                          City of New York, and you and HeteroMeta each waive
                          any objection to jurisdiction and venue in such
                          courts.
                          <br />
                          <br />
                          14. Dispute Resolution.
                          <br />
                          <br />
                          (a) Informal Dispute Resolution. You and HeteroMeta
                          must first attempt to resolve any dispute, claim, or
                          controversy arising out of or relating to these Terms,
                          or the breach, termination, enforcement,
                          interpretation, or validity thereof, or the use of the
                          Site (collectively, &ldquo;Disputes&rdquo;)
                          informally. Accordingly, neither you nor HeteroMeta
                          may start a formal arbitration proceeding for at least
                          sixty (60) days after one party notifies the other
                          party of a claim in writing. As part of this informal
                          resolution process, you must deliver a written notice
                          of any Dispute via first-class mail to us at
                          HeteroMeta. <br />
                          (b) Mandatory Arbitration of Disputes. We each agree
                          that any Dispute will be resolved solely by binding,
                          individual arbitration and not in a class,
                          representative, or consolidated action or proceeding.
                          You and HeteroMeta agree that the U.S. Federal
                          Arbitration Act governs the interpretation and
                          enforcement of these Terms, and that you and
                          HeteroMeta are each waiving the right to a trial by
                          jury or to participate in a class action. This
                          arbitration provision shall survive termination of
                          these Terms.
                          <br />
                          (c) Exceptions. As limited exceptions to Section 14(b)
                          above: (i) we both may seek to resolve a Dispute in
                          small claims court if it qualifies; and (ii) we each
                          retain the right to seek injunctive or other equitable
                          relief from a court to prevent (or enjoin) the
                          infringement or misappropriation of our intellectual
                          property rights.
                          <br />
                          (d) Conducting Arbitration and Arbitration Rules. The
                          arbitration will be conducted by the American
                          Arbitration Association (&ldquo;AAA&rdquo;) under its
                          Consumer Arbitration Rules (the &ldquo;AAA
                          Rules&rdquo;) then in effect, except as modified by
                          these Terms. The AAA Rules are available at
                          www.adr.org or by calling 1-800-778-7879. A party who
                          wishes to start arbitration must submit a written
                          Demand for Arbitration to AAA and give notice to the
                          other party as specified in the AAA Rules. The AAA
                          provides a form Demand for Arbitration at www.adr.org.
                          <br />
                          Any arbitration hearings will take place in the county
                          (or parish) where you live, with provision to be made
                          for remote appearances to the maximum extent permitted
                          by the AAA Rules, unless we both agree to a different
                          location. The parties agree that the arbitrator shall
                          have exclusive authority to decide all issues relating
                          to the interpretation, applicability, enforceability,
                          and scope of this arbitration agreement.
                          <br />
                          (e) Arbitration Costs. Payment of all filing,
                          administration and arbitrator fees will be governed by
                          the AAA Rules, and we will not seek to recover the
                          administration and arbitrator fees we are responsible
                          for paying, unless the arbitrator finds your Dispute
                          is frivolous. If we prevail in arbitration, we will
                          pay all of our attorneys&rsquo; fees and costs and
                          will not seek to recover them from you. If you prevail
                          in arbitration you will be entitled to an award of
                          attorneys&rsquo; fees and expenses to the extent
                          provided under applicable law.
                          <br />
                          (f) Injunctive and Declaratory Relief. Except as
                          provided in Section 14(c) above, the arbitrator shall
                          determine all issues of liability on the merits of any
                          claim asserted by either party and may award
                          declaratory or injunctive relief only in favor of the
                          individual party seeking relief and only to the extent
                          necessary to provide relief warranted by that
                          party&rsquo;s individual claim. To the extent that you
                          or we prevail on a claim and seek public injunctive
                          relief (that is, injunctive relief that has the
                          primary purpose and effect of prohibiting unlawful
                          acts that threaten future injury to the public), the
                          entitlement to and extent of such relief must be
                          litigated in a civil court of competent jurisdiction
                          and not in arbitration. The parties agree that
                          litigation of any issues of public injunctive relief
                          shall be stayed pending the outcome of the merits of
                          any individual claims in arbitration.
                          <br />
                          (g) Class Action Waiver. YOU AND HETEROMETA AGREE THAT
                          EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR
                          OR ITS INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR
                          CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
                          PROCEEDING. Further, if a Dispute is resolved through
                          arbitration, the arbitrator may not consolidate
                          another person&rsquo;s claims with your claims, and
                          may not otherwise preside over any form of a
                          representative or class proceeding. If this specific
                          provision is found to be unenforceable, then the
                          entirety of this Dispute Resolution section shall be
                          null and void.
                          <br />
                          (h) Severability. With the exception of any of the
                          provisions in Section 14(g) of these Terms
                          (&ldquo;Class Action Waiver&rdquo;), if an arbitrator
                          or court of competent jurisdiction decides that any
                          part of these Terms is invalid or unenforceable, the
                          other parts of these Terms will still apply.
                          <br />
                          <br />
                          15. General Terms.
                          <br />
                          <br />
                          (a) Reservation of Rights. HeteroMeta and its
                          licensors exclusively own all right, title, and
                          interest in and to the Site, including all associated
                          intellectual property rights. You acknowledge that the
                          Site is protected by copyright, trademark, and other
                          laws of the United States and foreign countries. You
                          agree not to remove, alter, or obscure any copyright,
                          trademark, service mark, or other proprietary rights
                          notices incorporated in or accompanying the Site.
                          <br />
                          (b) Entire Agreement. These Terms constitute the
                          entire and exclusive understanding and agreement
                          between HeteroMeta and you regarding use of the Site,
                          and these Terms supersede and replace all prior oral
                          or written understandings or agreements between
                          HeteroMeta and you regarding the Site. If any
                          provision of these Terms is held invalid or
                          unenforceable by an arbitrator or a court of competent
                          jurisdiction, that provision will be enforced to the
                          maximum extent permissible and the other provisions of
                          these Terms will remain in full force and effect.
                          Except where provided by applicable law in your
                          jurisdiction, you may not assign or transfer these
                          Terms, by operation of law or otherwise, without
                          HeteroMeta&rsquo; prior written consent. Any attempt
                          by you to assign or transfer these Terms absent our
                          consent or your statutory right, without such consent,
                          will be null and void in its entirety. HeteroMeta may
                          freely assign or transfer these Terms without
                          restriction. Subject to the foregoing, these Terms
                          will bind and inure to the benefit of the parties,
                          their successors, and any permitted assigns.
                          <br />
                          (c) Notices. Any notices or other communications
                          provided by HeteroMeta under these Terms will be given
                          by posting to the Site.
                          <br />
                          (d) Waiver of Rights. HeteroMeta&rsquo; failure to
                          enforce any right or provision of these Terms will not
                          be considered a waiver of such right or provision. The
                          waiver of any such right or provision will be
                          effective only if in writing and signed by a duly
                          authorized representative of HeteroMeta. Except as
                          expressly set forth in these Terms, the exercise by
                          either party of any of its remedies under these Terms
                          will be without prejudice to its other remedies under
                          these Terms or otherwise.
                          <br />
                        </p>
                      </p>

                      {/* -------- */}
                    </p>

                    {/* <p class="card-text">
                      <a class="readMore">Read More</a>
                    </p> */}
                  </div>
                </div>
                {/* <div class="partners-container">
                  {logos.map((element, index) => {
                    return (
                      <div class="partner-card-container" key={index}>
                        <a href="">
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div> */}
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { TermsOfUse };
