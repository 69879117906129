import "../../App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../Footer";
import { Container, Row, Col } from "react-grid-system";
import HeteroLogo from "../HeteroLogo";

function Design() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 600);
  });

  return (
    <ThemeProvider theme={theme}>
      <MobileView>
        {/*  ------------- Loading -------------- */}
        {isLoading ? (
          <div id="loading">
            <img id="loadingGifMobile" src="loading.gif" />
          </div>
        ) : (
          <>
           <HeteroLogo/>
            <Container className="container">
              {/* Title */}
              <p className="titleText">
                <i class="fa fa-arrow-left backTitle"></i>
                <a className="backTitle" href="/discover">
                  Back{" "}
                </a>
              </p>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Design
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
              </h1>

              <div class="card text-white bg-transparent">
                <br></br>
                <br></br>
                <img
                  className="cardImageContent"
                  src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_design_earth2.0(mobile).png?alt=media&token=8bafb0ec-16be-4a6b-9883-695db7da695c"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <br></br>
                  <p class="card-text cardContent">
                    Note from the whitepaper ‘’ Find your place in the universe
                    where real values are produced by logging into HeteroMeta,
                    and dive into Dimenzia* to experience this life.’’
                  </p>
                  <br></br>
                  <img
                    className="cardImageContent"
                    src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fdiscover_design_dimeszia-dikey%2003.png?alt=media&token=8768c9c1-9691-490b-b077-dc4fc51df90b"
                    alt="Card image cap"
                  ></img>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p class="card-text cardContent">
                    Every Dimenzia leads you to the endless posibilities to
                    produce and reproduce in the multi-dimensional environment
                    of metaverse, HeteroMeta. With this endless freedom of
                    choise, you can create your own ‘’avatar’’ by puting the
                    real values of yourself or your mindsets as you want.
                  </p>
                  <p class="card-text cardContent">
                    ‘’There is no abstract art. You must always start with
                    something. Afterwatd you can remove all traces of reality.’’
                    Pablo Picasso.
                    <br></br>
                    Remove all the traces of yours and show the real values.
                  </p>
                </div>
              </div>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <Footer></Footer>
            </Container>
          </>
        )}
      </MobileView>
      <BrowserView>
        {/*  ------------- Loading -------------- */}
        {isLoading ? (
          <div id="loading">
            <img id="loadingGif" src="../loading.gif" />
          </div>
        ) : (
          <>
            <HeteroLogo/>
            <Container className="container">
              {/* Title */}
              <p className="titleText">
                <i class="fa fa-arrow-left backTitle"></i>
                <a className="backTitle" href="/discover">
                  Back{" "}
                </a>
              </p>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Design
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Design</span>
                </span>
              </h1>

              <div class="card text-white bg-transparent">
                <br></br>
                <br></br>
                <img
                  className="cardImageContent"
                  src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2FDiscover_design_eart2.0.jpg?alt=media&token=ba11adb4-8eab-4fe2-91e5-5a9ab62215be"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <br></br>
                  <p class="card-text cardContent">
                    Note from the whitepaper ‘’ Find your place in the universe
                    where real values are produced by logging into HeteroMeta,
                    and dive into Dimenzia* to experience this life.’’
                  </p>
                  <br></br>
                  <img
                    className="cardImageContent"
                    src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fdiscover_design_content.jpg?alt=media&token=683ac4a8-cd53-45ae-9524-a71c341a4e5a"
                    alt="Card image cap"
                  ></img>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p class="card-text cardContent">
                    Every Dimenzia leads you to the endless posibilities to
                    produce and reproduce in the multi-dimensional environment
                    of metaverse, HeteroMeta. With this endless freedom of
                    choise, you can create your own ‘’avatar’’ by puting the
                    real values of yourself or your mindsets as you want.
                  </p>
                  <p class="card-text cardContent">
                    ‘’There is no abstract art. You must always start with
                    something. Afterwatd you can remove all traces of reality.’’
                    Pablo Picasso.
                    <br></br>
                    Remove all the traces of yours and show the real values.
                  </p>
                </div>
              </div>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <Footer></Footer>
            </Container>
          </>
        )}
      </BrowserView>

      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    </ThemeProvider>
  );
}

export { Design };
