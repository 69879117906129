import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import "./ExploreButton.css";
function ExploreButton() {
  return (
    <div className="explore-center-div">
      {/* <div class="button-container">
        <a class="buttonExplore" href="#">
          <span class="content">Explore</span>
          <span class="effect circle-1"></span>
          <span class="effect circle-2"></span>
        </a>
      </div> */}
      <button
        onClick={() =>
          window.open(
            "https://dimenzia.heterometa.com",
            "_blank",
            "noopener,noreferrer"
          )
        }
      >
        <div class="center">
          <div class="pulse">
            <h2>Explore</h2>
          </div>
        </div>
      </button>
    </div>
  );
}

export default ExploreButton;
