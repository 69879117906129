import ".././App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../Transition";
import firebaseConfig from "../firebaseConfig.js";
import { ref, getStorage, getDownloadURL, listAll } from "firebase/storage";
import HeteroLogo from "./HeteroLogo";

function Brands() {
  // -- Burger Menu -- \\
  const [logos, setLogos] = useState([]);
  const [brandups, setBrandups] = useState([]);
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });

  const getLogos = async () => {
    const storage = getStorage();
    const reference = ref(storage, "/markalar");
    listAll(reference)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {});
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((x) => {
            setUrl(x);
            setLogos((current) => [...current, x]);
          });
        });
      })
      .catch((error) => {});
  };

  const getBrandUps = async () => {
    const storage = getStorage();
    const reference = ref(storage, "/brandups");
    listAll(reference)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {});
        res.items.forEach((itemRef) => {
          getDownloadURL(itemRef).then((x) => {
            setUrl(x);
            setBrandups((current) => [...current, x]);
          });
        });
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getLogos();
    getBrandUps();
  }, []);

  function handeGetImages() {}

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <>
                {/* <Container className="container">
                  <p className="titleText">
                    BrandUps
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="partners-container">
                    {brandups.map((element, index) => {
                      return (
                        <div class="partner-card-container" key={index}>
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo-brandups big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                </Container> */}
                <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    BrandUps
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be won!
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be won!
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be won!
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be won!
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <p>
                    Brands that are reserved to be a part of the future of
                    HeteroMeta
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* 3 Cards */}
                  <div class="partners-container">
                    {logos.map((element, index) => {
                      return (
                        <div class="partner-card-container" key={index}>
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <>
                <HeteroLogo />
                {/* <Container className="container">
                  <p className="titleText">
                    BrandUps
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text"> The Future of HeteroMeta</span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="partners-container">
                    {brandups.map((element, index) => {
                      return (
                        <div class="partner-card-container" key={index}>
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo-brandups big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                </Container> */}
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Reserved
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be succeed
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be succeed
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be succeed
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Brands to be Altered, be valued, to be succeed
                      </span>
                    </span>
                  </h1>
                  <br></br>

                  <p class="card-text brandsDescription">
                    Brands that are reserved to be a part of the future of
                    HeteroMeta
                  </p>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <div class="partners-container">
                    {logos.map((element, index) => {
                      return (
                        <div class="partner-card-container" key={index}>
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { Brands };
