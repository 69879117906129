import "../../App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../../Transition";
import { ref, getStorage, getDownloadURL, listAll } from "firebase/storage";

function Privacy() {
  // -- Burger Menu -- \\
  const [logos, setLogos] = useState([]);
  const [url, setUrl] = useState();
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <Container className="container">
                {/* Title */}
                <p className="titleText">
                  PRIVACY POLICY
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                </p>
                {/* <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                </h1> */}
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                {/* 3 Cards */}

                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <Container className="container">
                {/* Title */}

                {/* <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text">
                      {" "}
                      Worldwide Partners, trusting the future of HeteroMeta.
                    </span>
                  </span>
                </h1> */}
                <br></br>
                <div class="card text-white bg-transparent mb-3 cardPadding">
                  {/* <img
                    className="cardImage"
                    src="/images/discover_01_dune.jpg"
                    alt="Card image cap"
                  ></img> */}
                  <div class="card-body bottomSheetCard">
                    <h5 class="card-title cardTitle"> </h5>
                    <p class="card-text bottomSheetContent">
                      <p className="titleText">
                        PRIVACY POLICY
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                        <div class="firefly"></div>
                      </p>
                      <p>
                        We are committed to respecting and protecting your
                        privacy and complying with any applicable law and
                        regulation regarding your personal information. This
                        Privacy Policy describes how we collect, use, disclose,
                        and protect your personal information, including across
                        our website, https://heterometa.com/, the HeteroMeta
                        game, and all other applications, content, smart
                        contracts, tools, features, and functionality offered on
                        or through our Website and the HeteroMeta game (the
                        “Services”), in each case operated by or on behalf of
                        HETEROMETA, (“we,” “us,” “our”). Please read this
                        Privacy Policy carefully. If you do not agree with this
                        Privacy Policy in general or any part of it, you should
                        not access the website, use our Services, or otherwise
                        provide your information to us.
                      </p>
                      <h5>Information We Collect</h5>
                      <p>
                        Information we collect falls into one of two categories:
                        “automatically collected” information and “voluntarily
                        provided” information. “Automatically collected”
                        information refers to any information automatically sent
                        by your devices in the course of accessing our Services.
                        “Voluntarily provided” information refers to any
                        information you knowingly and actively provide us when
                        using or participating in any of our Services.
                      </p>
                      <h5>Automatically Collected Information</h5>
                      <h6>Log Data</h6>
                      <p>
                        When you visit our Services, our servers may
                        automatically log the standard data provided by your web
                        browser. It may include your device’s Internet Protocol
                        (IP) address, geolocation data, your browser type and
                        version, the pages you visit, the time and date of your
                        visit, the time spent on each page, the referring site,
                        website elements clicked, and other details about your
                        visit.
                      </p>
                      <p>
                        Additionally, if you encounter certain errors while
                        using the site, we may automatically collect data about
                        the error and the circumstances surrounding its
                        occurrence. This data may include technical details
                        about your device, what you were trying to do when the
                        error happened, and other technical information relating
                        to the problem. You may or may not receive notice of
                        such errors, even in the moment they occur, that they
                        have occurred, or what the nature of the error is.
                        <br></br>
                        Please be aware that while this information may not be
                        personally identifying by itself, it may be possible to
                        combine it with other data to personally identify
                        individual persons.
                      </p>
                      <p>Device Data</p>
                      <p>
                        When you visit our website or interact with our
                        Services, we may automatically collect data about your
                        device, such as:
                      </p>
                      <p>Device Type</p>
                      <p>Operating System</p>
                      <p>Voluntarily Provided Information</p>
                      <p>Personal Information</p>
                      <p>
                        We may ask for personal information — for example, when
                        you register an account, make a purchase, request
                        support for our Services, or when you otherwise contact
                        us, including on Discord — which may include one or more
                        of the following:
                      </p>
                      <p>Name</p>
                      <p>Email address</p>
                      <p>Discord username, if you interact with us there</p>
                      <p>Digital Asset Wallet Address</p>
                      <p>
                        Other information necessary for Anti-Money Laundering
                        (“AML”), Counter Financing of Terrorism (“CFT”), and
                        Know Your Customer (“KYC”) compliance purposes
                      </p>
                      <p>
                        Any other information you choose to provide, such as
                        when you fill in a form, file a support ticket, provide
                        screenshots, conduct a search, update or add information
                        to your account, respond to surveys, participate in
                        promotions, correspond with us, or use other features of
                        our Services.
                      </p>
                      <p>Third-Party Information</p>
                      <p>
                        We may combine voluntarily provided and automatically
                        collected personal information with information that is
                        publicly available (such as on social media) or that you
                        provide to third parties that they share with us.
                      </p>
                      <p>Collection and Use of Information</p>
                      <p></p>
                      <p>
                        Other information necessary for Anti-Money Laundering
                        (“AML”), Counter Financing of Terrorism (“CFT”), and
                        Know Your Customer (“KYC”) compliance purposes
                      </p>
                      <p>
                        Any other information you choose to provide, such as
                        when you fill in a form, file a support ticket, provide
                        screenshots, conduct a search, update or add information
                        to your account, respond to surveys, participate in
                        promotions, correspond with us, or use other features of
                        our Services.
                      </p>
                      <p>Third-Party Information</p>
                      <p>
                        We may combine voluntarily provided and automatically
                        collected personal information with information that is
                        publicly available (such as on social media) or that you
                        provide to third parties that they share with us.
                      </p>
                      <p>Collection and Use of Information</p>
                      <p>
                        We may collect personal information from you when you do
                        any of the following on our Services:
                      </p>
                      <p>Register for an account</p>
                      <p>
                        Use a mobile device, personal computer, or web browser
                        to access our Services
                      </p>
                      <p>
                        Contact us via email, social media, chat programs, or on
                        any similar technologies
                      </p>
                      <p>When you mention us on social media</p>
                      <p>
                        We may use information for the following purposes, and
                        personal information will not be further processed in a
                        manner that is incompatible with these purposes:
                      </p>
                      <p>to provide you with our Services</p>
                      <p>
                        to enable you to customize or personalize your
                        experience of our Services
                      </p>
                      <p>to operate, improve, and develop our Services</p>
                      <p>to contact and communicate with you</p>
                      <p>
                        to enable you to access and use our Services, associated
                        interactive features, associated applications, and
                        associated social media or chat platforms
                      </p>
                      <p>
                        to comply with our legal obligations and resolve any
                        disputes that we may have
                      </p>
                      <p>
                        for security and fraud prevention, and to ensure that
                        our sites and apps are safe, secure, and used in line
                        with our Terms of Use
                      </p>
                      <p>Security of Your Personal Information</p>
                      <p>
                        When we collect and process personal information, and
                        while we retain this information, we will protect it
                        within commercially acceptable means to prevent loss and
                        theft, as well as unauthorized access, disclosure,
                        copying, use, or modification.
                      </p>
                      <p>
                        Although we will implement and maintain practices to
                        protect the personal information you provide to us, we
                        advise that no method of electronic transmission or
                        storage is 100% secure, and no one can guarantee
                        absolute data security. We recommend that you do not use
                        unsecure channels to communicate sensitive or
                        confidential information to us. Any information you
                        transmit to us you do at your own risk.<br></br>
                        You are responsible for selecting any password, to the
                        extent one is required, and its overall security
                        strength, ensuring the security of your own information
                        within the bounds of our services. For example, you are
                        responsible for ensuring any passwords associated with
                        accessing your personal information and accounts are
                        secure and confidential.
                        <br></br>
                        <br></br>
                        <h5>How Long We Keep Your Personal Information</h5>
                        <br></br>
                        We keep your personal information only for as long as we
                        need to. This time period may depend on what we are
                        using your information for, in accordance with this
                        Privacy Policy. For example, if you have provided us
                        with personal information as part of creating an account
                        with us, we may retain this information for the duration
                        your account exists on our system. If your personal
                        information is no longer required for this purpose, we
                        will delete it or make it anonymous by removing all
                        details that identify you.<br></br>
                        <br></br>
                        <h5>Children's Privacy</h5>
                        <br></br>
                        We do not aim any of our Services directly at children
                        under the age of 13, and we do not knowingly collect
                        personal information about children under 13. If we
                        become aware that we have unknowingly collected
                        information about a child under 13 years of age, we will
                        make commercially reasonable efforts to delete such
                        information from our database.<br></br>
                        If you are the parent or guardian of a child under 13
                        years of age who has provided us with their personal
                        information, you may contact us using the below
                        information to request that it be deleted.<br></br>
                        <p>
                          Disclosure of Personal Information to Third Parties
                        </p>
                        <br></br>
                        We may disclose personal information to third parties in
                        the following circumstances:<br></br>
                        with our parent, subsidiary, and/or affiliated entities;
                        <br></br>
                        with service providers for the purpose of enabling them
                        to provide our Services, including IT service providers,
                        data storage, hosting and server providers, analytics,
                        error loggers, debt collectors, maintenance or
                        problem-solving providers, professional advisors, and
                        payment systems operators;<br></br>
                        advertising partners;<br></br>
                        our employees, contractors, and/or related entities;
                        <br></br>
                        credit reporting agencies, courts, tribunals, and
                        regulatory authorities, in the event you fail to pay for
                        goods or services we have provided to you;<br></br>
                        courts, tribunals, regulatory authorities, and law
                        enforcement officers, as required by law, or any
                        obligations arising thereunder, or in connection with
                        any actual or prospective legal proceedings, or in order
                        to establish, exercise, or defend our legal rights or
                        those of a third party;<br></br>
                        to prevent physical harm, financial loss, or fraud on
                        our Services; and<br></br>
                        an entity that buys, or to which we transfer, all or
                        substantially all of our assets and business.<br></br>
                        <p>
                          Your Rights and Controlling Your Personal Information
                        </p>
                        <br></br>
                        Marketing permission:&#160;If you have previously agreed
                        to us using your personal information for direct
                        marketing purposes, you may change your mind at any time
                        by contacting us using the details below.<br></br>
                        HeteroMeta Support&#8232;Email: info@heterometa.com
                        <br></br>
                        Complaints:&#160;If you believe that we have breached a
                        relevant data protection law and wish to make a
                        complaint, please contact us using the details below and
                        provide us with full details of the alleged breach. We
                        will promptly investigate your complaint and respond to
                        you, in writing, setting out the outcome of our
                        investigation and the steps we will take to deal with
                        your complaint. You may also have the right to contact a
                        regulatory body or data protection authority in relation
                        to your complaint. Please see the EU/UK Addendum for
                        further detail.<br></br>
                        <p>Use of Cookies and Tracking Technologies</p>                  
                        <p>Cookies</p>
                        Our website, like most websites, uses cookies, pixels,
                        and other tracking technologies (&ldquo;Tracking
                        Technologies&rdquo;) to collect information about you
                        and your activity across our site. A cookie, for
                        example, is a small piece of data that our website
                        stores on your computer, and accesses each time you
                        visit, so we can understand how you use our site. A
                        pixel is a small portion of code that we use as part of
                        our Services. We use pixels to learn whether you have
                        clicked on certain web content. This helps us measure
                        and improve our services and personalize your
                        experience. Tracking Technologies help us to improve our
                        Services including by conducting statistical analyses,
                        estimating audience size and usage patterns, and
                        identifying a user&rsquo;s digital asset wallet address
                        and wallet provider.<br></br>
                      </p>
                      {/* -------- */}
                    </p>

                    {/* <p class="card-text">
                      <a class="readMore">Read More</a>
                    </p> */}
                  </div>
                </div>
                {/* <div class="partners-container">
                  {logos.map((element, index) => {
                    return (
                      <div class="partner-card-container" key={index}>
                        <a href="">
                          <div class="partner-logo-container">
                            <img
                              class="partner-logo big"
                              src={element}
                              alt="Syncspider partners - Aciety"
                            ></img>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div> */}
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { Privacy };
