import "../../App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../Footer";
import { Container, Row, Col } from "react-grid-system";
import HeteroLogo from "../HeteroLogo";

function WhatIsHeterometa() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 500);
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          {/*  ------------- Loading -------------- */}
          {isLoading ? (
            <div id="loading">
              <img id="loadingGifMobile" src="loading.gif" />
            </div>
          ) : (
            <>
             <HeteroLogo/>
              <Container className="container">
                {/* Title */}
                <p className="titleText">
                  <i class="fa fa-arrow-left backTitle"></i>
                  <a className="backTitle" href="/discover">
                    Back{" "}
                  </a>
                </p>
                <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                </h1>

                <div class="card text-white bg-transparent">
                  <br></br>
                  <img
                    className="cardImageContent"
                    src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_what%20is%20heterometa(mobile).png?alt=media&token=53be71f3-21fd-40bc-99d8-ed5f109e750a"
                    alt="Card image cap"
                  ></img>
                  <div class="card-body">
                    <br></br>
                    <p class="card-text cardContent">
                      ‘’ The world was a beautiful place… ‘’ <br></br> <br></br>
                      The HeteroMeta is a metaverse of Andromeda that provides a
                      universe to ‘’create’’, ‘’share’’ and ‘’win’’ together. It
                      aims to reach the true value of the brands by sperating
                      itself from its phyisical existence and reset its habits.
                      This is a important process and we strongly believe that
                      it is the time that we reach the pieces of the HeteroMeta
                      which are technologies, ideas, science and philosophies we
                      produced. To do this we need to reset our habits and alter
                      ourselfs and surroundings to a new form via Eart 2.0 it
                      contains dimensias to take you to the HeteroMeta which is
                      a decentralized digital environment where you/player can
                      craft faces, gates, nft’s and show their true values… It
                      is cyristal clear that the power of community can be an
                      extrimly important part of this industry.
                    </p>
                    <br></br>
                    {/* <p class="card-text cardContent">
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No...
    </p> */}
                  </div>
                </div>

                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            </>
          )}
        </MobileView>
        <BrowserView>
          {/*  ------------- Loading -------------- */}
          {isLoading ? (
            <div id="loading">
              <img id="loadingGif" src="../loading.gif" />
            </div>
          ) : (
            <>
             <HeteroLogo/>
              <Container className="container">
                {/* Title */}
                <p className="titleText">
                  <i class="fa fa-arrow-left backTitle"></i>
                  <a className="backTitle" href="/discover">
                    Back{" "}
                  </a>
                </p>
                <h1 class="jt --debug">
                  <span class="jt__row">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                  <span class="jt__row jt__row--sibling" aria-hidden="true">
                    <span class="jt__text"> What is HeteroMeta</span>
                  </span>
                </h1>

                <div class="card text-white bg-transparent">
                  <br></br>
                  <br></br>
                  <br></br>
                  <img
                    className="cardImageContent"
                    src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2FDiscover_what%20is%20heterometa.jpg?alt=media&token=09fee264-a158-4f06-8691-ca23ae2fe4da"
                    alt="Card image cap"
                  ></img>
                  <div class="card-body">
                    <br></br>
                    <br></br>
                    <p class="card-text cardContent">
                      ‘’ The world was a beautiful place… ‘’ <br></br> <br></br>
                      The HeteroMeta is a metaverse of Andromeda that provides a
                      universe to ‘’create’’, ‘’share’’ and ‘’win’’ together. It
                      aims to reach the true value of the brands by sperating
                      itself from its phyisical existence and reset its habits.
                      This is a important process and we strongly believe that
                      it is the time that we reach the pieces of the HeteroMeta
                      which are technologies, ideas, science and philosophies we
                      produced. To do this we need to reset our habits and alter
                      ourselfs and surroundings to a new form via Eart 2.0 it
                      contains dimensias to take you to the HeteroMeta which is
                      a decentralized digital environment where you/player can
                      craft faces, gates, nft’s and show their true values… It
                      is cyristal clear that the power of community can be an
                      extrimly important part of this industry.
                    </p>
                    <br></br>
                    {/* <p class="card-text cardContent">
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No... Note from the whitepaper, ‘’Why
      do we buy something? What is it we need? Is it the function
      of the product? Maybe. But is that the only factor? No...
      Note from the whitepaper, ‘’Why do we buy something? What is
      it we need? Is it the function of the product? Maybe. But is
      that the only factor? No...
    </p> */}
                  </div>
                </div>

                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <div class="firefly"></div>
                <Footer></Footer>
              </Container>
            </>
          )}
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { WhatIsHeterometa };
