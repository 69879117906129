import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import "./HeteroLogo.css";
import { BrowserView, MobileView } from "react-device-detect";
function HeteroLogo() {
  return (
    <>
      <BrowserView>
        <a className="heteroLeftLogo" href="/">
          <img src="/logo 01-01.png" alt="Rotate"></img>
        </a>
      </BrowserView>
      <MobileView>
        <a className="heteroLeftLogo-mobile" href="/">
          <img src="/logo 01-01.png" alt="Rotate"></img>
        </a>
      </MobileView>
    </>
  );
}

export default HeteroLogo;
