import ".././App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../Transition";
import { Html } from "@react-three/drei";
import { Canvas, useFrame } from "@react-three/fiber";
import HeteroLogo from "./HeteroLogo";

function Team() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 2000);
  });

  const flipCard = (event) => {
    console.log(" hey");
    console.log(event.currentTarget.style.transform);
    if (event.currentTarget.style.transform == "rotateY(180deg)") {
      event.currentTarget.style.transform = "rotateY(180deg)";
    } else {
      event.currentTarget.style.transform = "rotateX(180deg)";
    }

    //   color: var(--primary);
    // background: hsla(var(--hue), 25%, 10%, .2);
    // border-color: currentColor;
    // event.currentTarget.style.color = 'white';

    // event.currentTarget.classList.add('my-class-1', 'my-class-2');
  };
  return (
    <ThemeProvider theme={theme}>
      <>
        <MobileView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGifMobile" src="loading.gif" />
              </div>
            ) : (
              <>
               <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Team
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* 3 Cards */}
                </Container>
                <Col sm={4}>
                  <div class="flip-card-container">
                    <div class="flip-card">
                      <div class="card-front">
                        <figure>
                          {/* <div class="img-bg"></div> */}
                          <img
                            className="img-team-card"
                            src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F02-Front.png?alt=media&token=89713ff9-9040-4caf-b362-9d3589236d8e"
                            alt="Brohm Lake"
                          ></img>
                          {/* <figcaption>Guard</figcaption> */}
                        </figure>

                        {/* <ul>
          <li>Detail 1</li>
          <li>Detail 2</li>
          <li>Detail 3</li>
          <li>Detail 4</li>
          <li>Detail 5</li>
        </ul> */}
                      </div>

                      <div class="card-back">
                        <figure>
                          {/* <div class="img-bg"></div> */}
                          <img
                            className="img-team-card"
                            src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F02-Back.png?alt=media&token=4ce0f09f-3c20-4968-8ca0-9906e8be97ff"
                            alt="Brohm Lake"
                          ></img>
                        </figure>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div class="flip-card-container">
                    <div class="flip-card">
                      <div class="card-front">
                        <figure>
                          {/* <div class="img-bg"></div> */}
                          <img
                            className="img-team-card"
                            src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F03-Back.png?alt=media&token=139304b0-17fe-457a-b487-ec1a1f1867fd"
                            alt="Brohm Lake"
                          ></img>
                          {/* <figcaption>Guard</figcaption> */}
                        </figure>

                        {/* <ul>
          <li>Detail 1</li>
          <li>Detail 2</li>
          <li>Detail 3</li>
          <li>Detail 4</li>
          <li>Detail 5</li>
        </ul> */}
                      </div>

                      <div class="card-back">
                        <figure>
                          {/* <div class="img-bg"></div> */}
                          <img
                            className="img-team-card"
                            src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F03-Front.png?alt=media&token=1a92c9be-8bce-482f-b2a3-3203ee136f06"
                            alt="Brohm Lake"
                          ></img>
                        </figure>
                      </div>
                    </div>
                  </div>
                </Col>
                <Row>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F04-Front.png?alt=media&token=848eda6f-910b-487c-9940-b085729dc69b"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F04-Back.png?alt=media&token=dbefa5ce-c1e2-4ef6-9a60-295acaed34da"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F05-Back.png?alt=media&token=39dd0d46-2c27-49fc-ab40-dbd8ac60eb12"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F05-Front.png?alt=media&token=1fe352f8-9a16-4878-8fe2-a6e2a3992e03"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F07-Back.png?alt=media&token=89e7ab46-a14e-4949-913c-14d2b85dfee8"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F07-Front.png?alt=media&token=e8a7f498-3bd0-4483-af4a-5489c6ada852"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F08-Front.png?alt=media&token=cc1e4b29-b5b1-41fb-b172-be5127fdc056"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>
                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F08-Back.png?alt=media&token=7ff9b099-f24b-49ce-9bdd-9cfdb07e3657"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F09-Back.png?alt=media&token=472efd59-2f31-4dca-be92-cad1c592eb33"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F09-Front.png?alt=media&token=1e66eaa8-63e5-4649-9cfb-ca5f3685c59d"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F10-Back.png?alt=media&token=27b36ea8-8b95-45f9-b951-ce272123a23c"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F10-Front.png?alt=media&token=c04b03d3-f8be-47ba-93ea-5b36e2c7581b"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F06-Back.png?alt=media&token=50ff58a9-b391-4646-ac19-019c1e6e552c"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>
                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F06-Front.png?alt=media&token=d7ede99a-69ac-4e02-a52f-606c96dc92fe"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F11-Back.png?alt=media&token=35b8a61a-bfe5-4995-8cf9-940f5d2cfd87"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F11-Front.png?alt=media&token=99edcfd1-24e7-4179-bee7-fec0a0b2be56"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div class="flip-card-container">
                      <div class="flip-card">
                        <div class="card-front">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F12-Front.png?alt=media&token=562d7782-4319-4e38-a44b-2b9e59aa93e1"
                              alt="Brohm Lake"
                            ></img>
                            {/* <figcaption>Guard</figcaption> */}
                          </figure>

                          {/* <ul>
        <li>Detail 1</li>
        <li>Detail 2</li>
        <li>Detail 3</li>
        <li>Detail 4</li>
        <li>Detail 5</li>
      </ul> */}
                        </div>

                        <div class="card-back">
                          <figure>
                            {/* <div class="img-bg"></div> */}
                            <img
                              className="img-team-card"
                              src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2Fmobile%2F12-Back.png?alt=media&token=6892edd0-76f1-4b09-a50f-77d3246ae829"
                              alt="Brohm Lake"
                            ></img>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Container>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </MobileView>
        <BrowserView>
          <Transitions>
            {/*  ------------- Loading -------------- */}
            {isLoading ? (
              <div id="loading">
                <img id="loadingGif" src="loading.gif" />
              </div>
            ) : (
              <>
                <HeteroLogo />
                <Container className="container">
                  {/* Title */}
                  <p className="titleText">
                    Team
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </p>
                  <h1 class="jt --debug">
                    <span class="jt__row">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                    <span class="jt__row jt__row--sibling" aria-hidden="true">
                      <span class="jt__text">
                        {" "}
                        Core members to build new era of Metaverse.
                      </span>
                    </span>
                  </h1>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  <br></br>
                  {/* CONTENT */}
                  <Row>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F01-Back.png?alt=media&token=df7fdbc1-d442-4160-bbaf-b11686491773"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F01-Front.png?alt=media&token=23d4428a-4912-4d10-ab9e-4c510b6fcba1"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F02-Front.png?alt=media&token=fc6a3138-4c79-4d13-8c52-eacd3026c5bb"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F02-Back.png?alt=media&token=5058be55-ffe1-4ecd-b68b-b40a0bec4c73"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F03-Back.png?alt=media&token=d7bb07f4-2e72-4533-ae46-e1e925b461da"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F03-Front.png?alt=media&token=ea536906-eede-4a2d-9206-a3eb77fe99ea"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F04-Front.png?alt=media&token=12d953a1-de40-48e1-bffe-cdefb5304100"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F04-Back.png?alt=media&token=6b91fd15-028d-41cc-aca8-00dd822738c1"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F05-Back.png?alt=media&token=e73bf637-604c-422a-b6f5-e9e28aab7c0b"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F05-Front.png?alt=media&token=dd7bd077-101a-4a25-8af5-e4cb5849d3d1"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F06-Back.png?alt=media&token=bbf30299-b918-4c95-bf59-c139f82162de"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F06-Front.png?alt=media&token=0c68de11-6918-49ea-9e25-f51c09ce0d25"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F07-Back.png?alt=media&token=11f43546-df47-42f3-9bdc-b5795b8b11be"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>
                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F07-Front.png?alt=media&token=e0863a14-a94a-43cd-ad4d-6c5255c2b49d"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F08-Front.png?alt=media&token=c63b5d83-1536-485f-8bb4-809582181e23"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F08-Back.png?alt=media&token=c1419c34-674a-48ef-9349-ac332f7cb8ee"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F09-Back.png?alt=media&token=622a9931-2541-4be6-a4ca-6cee17dad1e9"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F09-Front.png?alt=media&token=3d61872b-2019-417f-a4fb-f24576289512"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F10-Back.png?alt=media&token=945a495f-a421-4312-8230-6bd2d96f4a33"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>
                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F10-Front.png?alt=media&token=ad1338aa-3649-4e56-8d33-baa1e6cc0226"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F11-Back.png?alt=media&token=30deeab1-7265-4381-9d86-e9ba6663bd0e"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F11-Front.png?alt=media&token=f995de64-07a7-41e2-8491-1df936990090"
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div class="flip-card-container">
                        <div class="flip-card">
                          <div class="card-front">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F12-Front.png?alt=media&token=21289c9f-da01-49f5-89ca-12f7a2cf1c5c"
                                alt="Brohm Lake"
                              ></img>
                              {/* <figcaption>Guard</figcaption> */}
                            </figure>

                            {/* <ul>
                              <li>Detail 1</li>
                              <li>Detail 2</li>
                              <li>Detail 3</li>
                              <li>Detail 4</li>
                              <li>Detail 5</li>
                            </ul> */}
                          </div>

                          <div class="card-back">
                            <figure>
                              {/* <div class="img-bg"></div> */}
                              <img
                                className="img-team-card"
                                src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/TeamCards%2F12-Back.png?alt=media&token=37c5a816-43b8-43cb-92b2-dd1200299567 "
                                alt="Brohm Lake"
                              ></img>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>

                <Container>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <div class="firefly"></div>
                  <Footer></Footer>
                </Container>
              </>
            )}
          </Transitions>
        </BrowserView>

        <div ref={node}>
          <FocusLock disabled={!open}>
            <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
}

export { Team };
