import ".././App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import Transitions from "../Transition";
import emailjs from "@emailjs/browser";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
function Contact2() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mhf9i0a",
        "template_uz46d1n",
        form.current,
        "GvnpF6HMJRbYT7dpT"
      )
      .then(
        (result) => {
          console.log(result.text);
          confirmAlert({
            title: "Thank you for concat us ",
            message: "We will inform you!",
            buttons: [
              {
                label: "OK",
                onClick: () => cleanFields(),
              },
            ],
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  function cleanFields() {
    // form.reset();
    document.getElementById("create-course-form").reset();
  }

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 600);
  });

  return (
    <ThemeProvider theme={theme}>
      <MobileView>
        {isLoading ? (
          <div id="loading">
            <img id="loadingGif" src="../loading.gif" />
          </div>
        ) : (
          <>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="contactContainer-mobile">
              {/* Title */}
              <p className="titleText">Contact</p>
              <br></br>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Get In Touch
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Get In Touch</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Get In Touch</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Get In Touch</span>
                </span>
              </h1>

              <br></br>
              <br></br>
              <Row>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://litepaper.heterometa.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    {/* <img
                      src="/contact-logo/1.png"
                      className="img-contact-mobile"
                      alt="FCC Logo"
                    /> */}
                    LITEPAPER
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/2.png"   className="img-contact-mobile" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://whitepaper.heterometa.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    {/* <img
                      src="/contact-logo/2.png"
                      className="img-contact-mobile"
                      alt="FCC Logo"
                    /> */}
                    WHITEPAPER
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/3.png"    className="img-contact-mobile" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button class="button-40" role="button">
                    {/* <img
                      src="/contact-logo/3.png"
                      className="img-contact-mobile"
                      alt="FCC Logo"
                    /> */}
                    MAIL
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/4.png" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://docsend.com/view/4b49xrud6qsbynmp",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    PITCH DECK
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/5.png" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://twitter.com/HeteroMeta",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    TWITTER
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/6.png" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/heterometa/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    LINKEDIN
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/7.png" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://instagram.com/heterometa?igshid=MjkzY2Y1YTY=",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    INSTAGRAM
                  </button>
                </Col>
              </Row>
              <Row>
                {/* <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container-mobile">
                    <img src="/contact-logo/7.png" alt="FCC Logo" />
                  </div>
                </Col> */}
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/watch?v=KMXDj9MeowU",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    I'MMERSE
                  </button>
                </Col>
              </Row>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
            </div>
            <Footer></Footer>
          </>
        )}
      </MobileView>
      <BrowserView>
        {/*  ------------- Loading -------------- */}
        {isLoading ? (
          <div id="loading">
            <img id="loadingGif" src="../loading.gif" />
          </div>
        ) : (
          <Container className="container">
            <div className="contactContainer">
              {/* Title */}
              <p className="titleText">Contact</p>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Get In Touch<br></br> With Us
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text">
                    {" "}
                    Get In Touch<br></br> With Us
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text">
                    {" "}
                    Get In Touch<br></br> With Us
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text">
                    {" "}
                    Get In Touch<br></br> With Us
                  </span>
                </span>
              </h1>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <br></br>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/1.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://litepaper.heterometa.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    LITEPAPER
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/2.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://whitepaper.heterometa.com/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    WHITEPAPER
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/3.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button class="button-40" role="button">
                    MAIL
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/4.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://docsend.com/view/4b49xrud6qsbynmp",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    PITCH DECK
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/5.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://twitter.com/HeteroMeta",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    TWITTER
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/6.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://www.linkedin.com/company/heterometa/",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    LINKEDIN
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    <img src="/contact-logo/7.png" alt="FCC Logo" />
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://instagram.com/heterometa?igshid=MjkzY2Y1YTY=",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    INSTAGRAM
                  </button>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  {" "}
                  <div class="contact-icon-v2-container">
                    {/* <img src="/contact-logo/7.png" alt="FCC Logo" /> */}
                  </div>
                </Col>
                <Col sm={8}>
                  <button
                    class="button-40"
                    role="button"
                    onClick={() =>
                      window.open(
                        "https://www.youtube.com/watch?v=KMXDj9MeowU",
                        "_blank",
                        "noopener,noreferrer"
                      )
                    }
                  >
                    I'MMERSE
                  </button>
                </Col>
              </Row>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
            </div>
            <Footer></Footer>
          </Container>
        )}
      </BrowserView>

      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    </ThemeProvider>
  );
}

export { Contact2 };
