import ".././App.css";
import React, { Suspense, useEffect, useState, useRef } from "react";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "./Footer";
import { Container, Row, Col } from "react-grid-system";
import TransitionsLong from "../TransitionLong";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import { EffectComposer, DepthOfFieldEffect } from "postprocessing";
import { useControls, folder } from "leva";
import * as THREE from "three";
import ReactPlayer from "react-player/youtube";
import { useNavigate } from "react-router-dom";
import EarthLogo from "./EarthLogo";
import { Button, Popover, Whisper } from "rsuite";
import "rsuite/dist/rsuite.min.css";

function Galaxy({ dof }) {
  const parameters = useControls({
    Galaxy: folder({
      // count: { min: 100, max: 1000000, value: 471700, step: 100 },
      // size: { min: 0.001, max: 0.1, value: 0.005, step: 0.001 },
      // radius: { min: 0.01, max: 20, value: 9.4, step: 0.01 },
      // branches: { min: 2, max: 20, value: 10, step: 1 },
      // spin: { min: -5, max: 5, value: 10, step: 0.001 },
      // randomness: { min: 0, max: 2, value: 0.79, step: 0.001 },
      // randomnessPower: { min: 1, max: 10, value: 2.75, step: 0.001 },
      // insideColor: { value: "#ff6030", label: "Inside Color" },
      // outsideColor: { value: "#1b3984", label: "Outside Color" },
    }),
    // Animation: folder({
    //   animate: true,
    //   mouse: false,
    //   enter: false,
    //   //speed: { value: 0.3, min: 0, max: 2, render: (get) => get('animation.animate') },
    // }),
    // // DoF: folder({
    // //   opacity: {
    // //     min: 0,
    // //     max: 1,
    // //     value: 1,
    // //     steps: 0.01,
    // //   },
    // //   focusDistance: {
    // //     min: 0,
    // //     max: 1.0,
    // //     value: 0.05,
    // //     steps: 0.001,
    // //   },
    // //   focalLength: {
    // //     min: 0,
    // //     max: 0.1,
    // //     value: 0.05,
    // //     steps: 0.0001,
    // //   },
    // //   width: {
    // //     min: 0,
    // //     max: 1280,
    // //     value: 480,
    // //   },
    // //   height: {
    // //     min: 0,
    // //     max: 1280,
    // //     value: 480,
    // //   },
    // //   focusX: {
    // //     min: -1,
    // //     max: 1,
    // //     value: 0,
    // //   },
    // //   focusY: {
    // //     min: -1,
    // //     max: 1,
    // //     value: 0,
    // //   },
    // //   focusZ: {
    // //     min: -1,
    // //     max: 1,
    // //     value: 0,
    // //   },
    // }),
  });
  const particles = useRef();
  //const [movement] = useState(() => new THREE.Vector3())
  const [temp] = useState(() => new THREE.Vector3());
  const [focus] = useState(() => new THREE.Vector3());

  useEffect(() => {
    generateGalaxy();
  });

  useFrame((state, delta) => {
    //dof.current.target = focus.lerp(particles.current.position, 0.05)
    //movement.lerp(temp.set(state.mouse.x, state.mouse.y * 0.2, 0), 0.2)
    if (dof.current) {
      dof.current.circleOfConfusionMaterial.uniforms.focusDistance.value =
        parameters.focusDistance;
      dof.current.circleOfConfusionMaterial.uniforms.focalLength.value =
        parameters.focalLength;
      dof.current.resolution.height = parameters.height;
      dof.current.resolution.width = parameters.width;
      dof.current.target = new THREE.Vector3(
        parameters.focusX,
        parameters.focusY,
        parameters.focusZ
      );
      dof.current.blendMode.opacity.value = parameters.opacity;
    }

    // if (parameters.mouse) {
    if (true) {
      //particles.current.position.x = THREE.MathUtils.lerp(particles.current.position.x, state.mouse.x * 20, 0.2)
      particles.current.rotation.x = THREE.MathUtils.lerp(
        particles.current.rotation.x,
        state.mouse.y / 10,
        0.2
      );
      particles.current.rotation.y = THREE.MathUtils.lerp(
        particles.current.rotation.y,
        -state.mouse.x / 2,
        0.2
      );
    }

    // TODO use delta instead
    // if (parameters.animate) {
    if (true) {
      const elapsedTime = state.clock.getElapsedTime();
      particles.current.rotation.y = 0.05 * elapsedTime;
    }

    // if (parameters.enter) {
    //   9.4 = 0;
    //   console.log(parameters.radius);
    // }
  });

  const generateGalaxy = () => {
    const positions = new Float32Array(471700 * 3);
    const colors = new Float32Array(471700 * 3);
    //const colorInside = new THREE.Color(parameters.insideColor)
    //const colorOutside = new THREE.Color(parameters.outsideColor)
    const colorInside = new THREE.Color(1.0, 0.3765, 0.1882);
    const colorOutside = new THREE.Color(0.10588, 0.22353, 0.51765);

    for (let i = 0; i < 471700; i++) {
      const i3 = i * 3;

      const radius = Math.random() * 9.4;
      const spinAngle = radius * 10;
      const branchAngle = ((i % 10) / 10) * Math.PI * 2;

      const randomX =
        Math.pow(Math.random(), 2.75) *
        (Math.random() < 0.5 ? 1 : -1) *
        0.79 *
        radius;
      const randomY =
        Math.pow(Math.random(), 2.75) *
        (Math.random() < 0.5 ? 1 : -1) *
        0.79 *
        radius;
      const randomZ =
        Math.pow(Math.random(), 2.75) *
        (Math.random() < 0.5 ? 1 : -1) *
        0.79 *
        radius;

      positions[i3] = Math.cos(branchAngle + spinAngle) * radius + randomX;
      positions[i3 + 1] = randomY;
      positions[i3 + 2] = Math.sin(branchAngle + spinAngle) * radius + randomZ;

      const mixedColor = colorInside.clone();
      mixedColor.lerp(colorOutside, radius / 9.4);

      colors[i3] = mixedColor.r;
      colors[i3 + 1] = mixedColor.g;
      colors[i3 + 2] = mixedColor.b;
    }

    particles.current.geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    particles.current.geometry.setAttribute(
      "color",
      new THREE.BufferAttribute(colors, 3)
    );
  };

  return (
    <points ref={particles}>
      <bufferGeometry />
      <pointsMaterial
        size={0.005}
        sizeAttenuation={true}
        depthWrite={true}
        vertexColors={true}
        blending={THREE.AdditiveBlending}
      />
    </points>
  );
}

function Knot(props) {
  const knotRef = useRef();
  const [clicked, setClicked] = useState(false);
  const markerRef = useRef();
  const vec = new THREE.Vector3();
  const dof = useRef();
  useFrame((state) => {
    if (props.animated) {
      console.log("clicked");
      state.camera.lookAt(knotRef.current.position);
      state.camera.position.lerp(vec.set(0.1, 0.1, 0.1), 0.012);
      state.camera.updateProjectionMatrix();
      console.log(props.animated);
    }
    return null;
  });
  useFrame(({ clock }) => {
    knotRef.current.rotation.y += -0.005;
    // knotRef.current.position.z = Math.sin(clock.getElapsedTime()) * 10 - 10;
  });

  return (
    <mesh ref={knotRef} position={props.position}>
      <sphereGeometry args={[0.01, 0.01, 0.01, 1]} />
      <meshLambertMaterial color={"#ff6030"} />
    </mesh>
  );
}

//function BlackHoleNucleus({ size }) {
//  const meshRef = useRef();
//
//  return (
//    <mesh ref={meshRef} scale={[size, size, size]} position={[0, 0, 0]}>
//      <sphereBufferGeometry
//        attach="geometry"
//        args={[0.5, 32, 32, 0, 6.4, 0, 6.3]}
//      />
//      <meshBasicMaterial attach="material" color="#000" />
//    </mesh>
//  );
//}

function Nucleus({ size }) {
  const nucleusRef = useRef();
  const color = new THREE.Color();
  color.setHSL(Math.random(), 0.7, Math.random() * 0.2 + 0.05);

  return (
    <mesh ref={nucleusRef} position={[0, 0, 0]} scale={[size, size, size]}>
      <sphereBufferGeometry
        attach="geometry"
        args={[0.5, 32, 32, 0, 6.4, 0, 6.3]}
      />
      <meshBasicMaterial attach="material" color={"#fff"} />
    </mesh>
  );

  //const geometry = new THREE.IcosahedronGeometry( 1, 15 );

  //		for ( let i = 0; i < 50; i ++ ) {

  //			const color = new THREE.Color();
  //			color.setHSL( Math.random(), 0.7, Math.random() * 0.2 + 0.05 );

  //			const material = new THREE.MeshBasicMaterial( { color: color } );
  //			const sphere = new THREE.Mesh( geometry, material );
  //			sphere.position.normalize().multiplyScalar( Math.random() * 4.0 + 2.0 );
  //			sphere.scale.setScalar( Math.random() * Math.random() + 0.5 );
  //			scene.add( sphere );

  //			if ( Math.random() < 0.25 ) sphere.layers.enable( BLOOM_SCENE );

  //    }
}

const Effects = React.forwardRef((props, ref) => {
  const { bokehScale } = useControls({
    DoF: folder({
      bokehScale: {
        min: 0,
        max: 10,
        value: 1,
      },
    }),
  });
  return (
    <EffectComposer multisampling={0}>
      <DepthOfFieldEffect ref={ref} bokehScale={bokehScale} />
    </EffectComposer>
  );
});

function Dodecahedron() {
  const { viewport } = useThree();
  // viewport = canvas in 3d units (meters)

  const ref = useRef();
  useFrame(({ mouse }) => {
    const x = (mouse.x * viewport.width) / 2;
    const y = (mouse.y * viewport.height) / 2;
    ref.current.position.set(x, y, 0);
    ref.current.rotation.set(-y, x, 0);
    ref.current.radius = 1;
  });

  return (
    <mesh ref={ref}>
      <dodecahedronBufferGeometry />
      <meshNormalMaterial />
      {/* <Html distanceFactor={10}>
        <div class="content">
          hello <br />
          world
        </div>
      </Html> */}
    </mesh>
  );
}

function Welcome() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  let navigate = useNavigate();
  const dof = useRef();
  const [animate, setAnimate] = useState(false);
  const [youtubePlay, setYoutubePlay] = useState(false);
  // if (animate) {
  //   useState(() => {
  //     setTimeout(function () {
  //       navigate("../");
  //     }, 1500);
  //   });
  // }
  function handleAnimate() {
    setAnimate(true);
    // console.log('animation is start');
    setTimeout(function () {
      navigate("../home");
    }, 2000);
  }

  function handlePlayPauseYoutube() {
    console.log(youtubePlay);
    setYoutubePlay(!youtubePlay);
  }
  //return (
  //  <Canvas linear flat colorManagement={false} style={{ height: `100vh` }} camera={{ position: [0, 2, 5] }}>
  //    <pointLight position={[15, 15, 15]} intensity={1} />
  //    <Suspense fallback={null}>
  //      <Galaxy dof={dof} />
  //      <Knot position={[0, 0, 0]} />
  //    </Suspense>
  //    <Effects ref={dof} />
  //    <axesHelper args={[2, 2, 2]} />
  //  </Canvas>
  //)

  // TODO do real color management
  return (
    <ThemeProvider theme={theme}>
      <BrowserView>
        <a
          className="welcomeEarth"
          onClick={() =>
            window.open(
              "https://dimenzia.heterometa.com",
              "_blank",
              "noopener,noreferrer"
            )
          }
        >
          <img src="/starter_iconss/earth2.0.png" alt="Rotate"></img>
        </a>
        {/* <EarthLogo></EarthLogo> */}
        <div className="hetero-left-welcome-logo">
          <img src="/starter_iconss/heterometa.png" alt="Rotate"></img>
        </div>
        <button
          onClick={() => handlePlayPauseYoutube()}
          className="welcome-youtube-video"
          // href="https://www.youtube.com/watch?v=_XCdWoMUvh4"
        >
          {/* <img src="/starter_iconss/watch.png" alt="Rotate"></img> */}
        </button>
        <div className="youtube-video-panel">
          <ReactPlayer
            url="https://www.youtube.com/watch?v=rIxfc3EuAsE"
            width="30vw"
            height="30vh"
            playing={youtubePlay}
          />
        </div>
        <TransitionsLong>
          <div
            style={{
              textAlign: "center",
              position: "fixed",
              zIndex: "104",
              left: "47%",
              top: "45%",
            }}
          >
            {/* <Whisper
              placement="topStart"
              followCursor
              speaker={
                <Popover className="mouse-popover">
                  Tap to start Journey
                </Popover>
              }
            >
              <Button
                onClick={() => handleAnimate()}
                className="welcome-mid-button"
              ></Button>
            </Whisper> */}
          </div>
          <div className="hetero-legt-welcome">
            <h3 className="hetero-legt-welcome-title">
              TAP TO START JOURNEY...
            </h3>
          </div>
          <button className="galaxy" onClick={() => handleAnimate()}>
            <Canvas linear flat camera={{ position: [0, 0, 10] }}>
              <Suspense fallback={null}>
                <Galaxy dof={dof} />
                <Nucleus size={0.125} />
                <Knot position={[0, 0, 0]} animated={animate} />
              </Suspense>
              {/* <Dodecahedron /> */}
            </Canvas>
          </button>
        </TransitionsLong>
      </BrowserView>
      <MobileView>
        <a className="welcomeEarth" href="/earth">
          <img src="/starter_iconss/earth2.0.png" alt="Rotate"></img>
        </a>
        <div className="hetero-left-welcome-logo">
          <img src="/starter_iconss/heterometa.png" alt="Rotate"></img>
        </div>
       
        <a
          className="welcome-youtube-video"
          href="https://www.youtube.com/watch?v=rIxfc3EuAsE"
        >
          <img src="/starter_iconss/watch.png" alt="Rotate"></img>
        </a>

        <TransitionsLong>
        <div className="hetero-legt-welcome-mobile">
          <h3 className="hetero-legt-welcome-title-mobile">TAP TO START JOURNEY...</h3>
        </div>
          <button className="galaxy-mobile" onClick={() => handleAnimate()}>
            <Canvas
              linear
              flat
              camera={{ position: [0, 0, 10] }}
              className="canvas-mobile"
            >
              <Suspense fallback={null}>
                {/* <OrbitControls
                enableZoom={true}
                // maxAzimuthAngle={Math.PI / 2}
                maxPolarAngle={Math.PI}
                // minAzimuthAngle={-Math.PI / 2}
                // maxZoom={10}
                // minZoom={100}
                enablePan={true}
                //
              ></OrbitControls> */}
                <Galaxy dof={dof} />
                {/* <Universe /> */}
                <Nucleus size={0.125} />
                <Knot position={[0, 0, 0]} animated={animate} />
              </Suspense>
              {/* <Effects ref={dof} /> */}
              {/* <axesHelper args={[2, 2, 2]} /> */}
            </Canvas>
          </button>
        </TransitionsLong>
      </MobileView>
      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    </ThemeProvider>
  );
}
export { Welcome };
