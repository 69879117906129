import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyATn3c1lO8m2FbiryUX8cgtc4iE_kp0nFk",
    authDomain: "heterometadev-4d7aa.firebaseapp.com",
    projectId: "heterometadev-4d7aa",
    storageBucket: "heterometadev-4d7aa.appspot.com",
    messagingSenderId: "620561930614",
    appId: "1:620561930614:web:00f9ecb03929fbe83c8257",
    measurementId: "G-TCVV00VZ3H"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;