import React, { Suspense, useEffect, useState, useRef, useMemo } from "react";
import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { useNavigate } from "react-router-dom";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { ThemeProvider } from "styled-components";
import { OrbitControls, Stars, Text } from "@react-three/drei";
import { Particles } from "../Particles";
import * as THREE from "three";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import EarthLogo from "../EarthLogo";

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls class.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls

  const {
    camera,
    gl: { domElement },
  } = useThree();

  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef();
  useFrame((state) => controls.current.update());
  return (
    <OrbitControls
      ref={controls}
      args={[camera, domElement]}
      enableZoom={false}
      // maxAzimuthAngle={Math.PI / 2}
      maxPolarAngle={Math.PI}
      // minAzimuthAngle={-Math.PI / 2}
      // maxZoom={10}
      // minZoom={100}
      enablePan={false}
      // minPolarAngle={0}
    />
  );
};

const Menu = ({ open, ...props }) => {
  let navigate = useNavigate();

  useEffect(() => {
    console.clear();
    /* Js */
    let aphorisms = ["Use your mouse ", "to look around"];

    let data = {
      aphorisms: aphorisms,
    };

    function BlipText(
      el,
      copyArray,
      flickerSpeed = 50,
      holdDelay = 2000,
      noise = "-*.°"
    ) {
      this.contentArray = copyArray;
      this.contentCount = 0;
      this.charCount = 0;
      this.domElement = el;
      this.flickerSpeed = flickerSpeed;
      this.holdDelay = holdDelay;
      this.noise = noise.split("");
      this.noiseCount = 0;
      this.compose(this.contentArray[this.contentCount++]);
    }
    BlipText.prototype.compose = function (txt) {
      this.noiseCount = 0;
      this.charCount = 0;
      this.flickerUp(txt);
    };
    BlipText.prototype.decompose = function () {
      this.noiseCount = 0;
      this.charCount = 0;
      this.flickerDown();
    };
    BlipText.prototype.flickerUp = function (txt) {
      let t = txt.substr(0, this.charCount);
      this.domElement.textContent = t + this.noise[this.noiseCount++];
      if (this.noiseCount < this.noise.length) {
        setTimeout(() => this.flickerUp(txt), this.flickerSpeed);
      } else if (this.charCount++ < txt.length - 1) {
        this.noiseCount = 0;
        setTimeout(() => this.flickerUp(txt), this.flickerSpeed);
      } else {
        this.domElement.textContent = txt;
        setTimeout(() => this.decompose(), this.holdDelay);
      }
    };
    BlipText.prototype.flickerDown = function () {
      let t = this.domElement.textContent.slice(0, -1);
      this.domElement.textContent = t + this.noise[this.noiseCount++];
      if (this.noiseCount < this.noise.length) {
        setTimeout(() => this.flickerDown(), this.flickerSpeed);
      } else if (t.length > 0) {
        this.noiseCount = 0;
        this.domElement.textContent = this.domElement.textContent.slice(0, -1);
        setTimeout(() => this.flickerDown(), this.flickerSpeed);
      } else {
        this.domElement.textContent = "";
        this.compose(this.contentArray[this.contentCount++]);
        if (this.contentCount >= this.contentArray.length) {
          this.contentCount = 0;
        }
      }
    };

    let blipTargets = [...document.querySelectorAll(".blip-text")];
    for (let i = 0; i < blipTargets.length; i++) {
      let dataSet = blipTargets[i].dataset;
      let blipper = new BlipText(
        blipTargets[i],
        data[dataSet.src],
        Number(dataSet.flicker),
        Number(dataSet.hold),
        dataSet.noise
      );
    }

    /* ---------------------------------- */
  }, []);

  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  function Word({ children, ...props }) {
    const color = new THREE.Color();
    const fontProps = {
      font: "/fonts/Andromeda-eR2n.ttf",
      fontSize: 2.8,
      letterSpacing: -0.05,
      lineHeight: 2,
      "material-toneMapped": false,
    };
    const ref = useRef();
    const [hovered, setHovered] = useState(false);
    const over = (e) => (e.stopPropagation(), setHovered(true));
    const out = () => setHovered(false);
    // Change the mouse cursor on hover
    useEffect(() => {
      if (hovered) document.body.style.cursor = "pointer";
      return () => (document.body.style.cursor = "auto");
    }, [hovered]);
    // Tie component to the render-loop
    useFrame(({ camera }) => {
      // Make text face the camera
      ref.current.quaternion.copy(camera.quaternion);
      // Animate font color
      ref.current.material.color.lerp(
        color.set(hovered ? "#e52dc5" : "white"),
        0.1
      );
    });

    function handleNavigate() {
      console.log(children);
      if (children == "Journey") navigate("../home");
      if (children == "Discover") navigate("../discover");
      if (children == "Partners") navigate("../partners");
      if (children == "Team") navigate("../team");
      if (children == "BrandUps") navigate("../brandups");
      if (children == "Litepaper") {
        window.open(
          "https://litepaper.heterometa.com",
          "_blank",
          "noopener,noreferrer"
        );
        // window.location.replace("https://heterometa-whitepaper.web.app");
        // React.useEffect(() => {
        //   window.location.replace('https://heterometa-whitepaper.web.app')
        // }, [])
      }
    }

    return (
      // <Float floatIntensity={3} speed={2}>
      //    </Float>
      <Text
        ref={ref}
        onPointerOver={over}
        onPointerOut={out}
        {...props}
        {...fontProps}
        children={children}
        onClick={handleNavigate}
      ></Text>
    );
  }

  function handleNavigateEarth() {
    navigate("../earth");
  }

  function Cloud({ count = 4, radius = 20 }) {
    useFrame(() => {});

    // Create a count x count random words with spherical distribution
    const words = useMemo(() => {
      const temp = [];
      const spherical = new THREE.Spherical();
      const phiSpan = Math.PI / (count + 1);
      const thetaSpan = (Math.PI * 2) / count;
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 1, thetaSpan * 0)
        ),
        "Journey",
      ]);
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 1, thetaSpan * 1)
        ),
        "Discover",
        "news",
      ]);
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 1, thetaSpan * 2)
        ),
        "Team",
      ]);
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 2, thetaSpan * 3)
        ),
        "Litepaper",
      ]);
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 2, thetaSpan * 4)
        ),
        "Partners",
      ]);
      temp.push([
        new THREE.Vector3().setFromSpherical(
          spherical.set(radius, phiSpan * 2, thetaSpan * 5)
        ),
        "BrandUps",
      ]);
      // for (let i = 1; i < count + 1; i++)
      //   // Taken from https://discourse.threejs.org/t/can-i-place-obects-on-a-sphere-surface-evenly/4773/6
      //   for (let j = 0; j < count; j++)
      //     temp.push([
      //       new THREE.Vector3().setFromSpherical(
      //         spherical.set(radius, phiSpan * i, thetaSpan * j)
      //       ),
      //       "heterometa",
      //     ]);
      return temp;
    }, [count, radius]);
    return words.map(([pos, word], index) => (
      <Word key={index} position={pos} children={word} />
    ));
  }

  return (
    <>
      <BrowserView>
        <div>
          <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
            <div className="leftBottomContainer">
              <div class="container">
                <div class="row">
                  <div class="col-sm-2">
                    <img
                      className="lookAroundpng"
                      src="/images/menu_rotate_icon.png"
                      alt="Rotate"
                    ></img>
                  </div>
                  <div class="col-sm-10">
                    <p className="menuBottomText">
                      -Use your mouse to look around
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <EarthLogo />
            {/* camera={{ fov: 70, position: [-30, 30, 20] }} */}
            <Canvas camera={{ fov: 70, position: [-42, 30, 20] }}>
              {/* <OrbitControls  /> */}
              <CameraControls />
              {/* <Stars factor={2} /> */}
              {/* <ambientLight intensity={0.5} />
              <spotLight position={[20, 20, 20]} angle={1} /> */}
              <OrbitControls makeDefault enableZoom={false} />
              <ambientLight />
              <Suspense fallback={null}>
                <Particles />
              </Suspense>
              {/* <MenuItemThree />
            <MenuItemThree /> */}
              {/* <Text color="white" fov={60} position={[120, 145, 120]} fontSize={55}>
              hello world!
            </Text> */}
              <fog attach="fog" args={["#202025", 20, 100]} />
              <Cloud count={3} radius={22} anchorX="center" anchorY="middle" />
              {/* <TrackballControls /> */}
            </Canvas>
            <div className="rightBottomContainer">
              <div class="flex-center">
                <a href="#">
                  <i class="fa-brands fa-discord2 fa-1x menu-footer-link">
                    0x3df49d,66.998 kpc,Kepler-452b,NGC 224
                  </i>{" "}
                </a>
                <a href="/contact">
                  <i class="fa-brands fa-discord2 fa-1x icon-3d menu-footer-link-text">
                    Contact
                  </i>{" "}
                </a>
                <button
                  onClick={() =>
                    window.open(
                      "https://discord.gg/WuvmMPh5rY ",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="discord-button"
                  className="social-button"
                >
                  <i class="fa-brands fa-discord fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://twitter.com/HeteroMeta",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="twitter-button"
                  className="social-button"
                >
                  <i class="fa fa-twitter fa-1x icon-3d"></i>
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.instagram.com/heterometa/",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="instagram-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-instagram fa-1x icon-3d"></i>{" "}
                </button>
                <button
                  onClick={() =>
                    window.open(
                      "https://www.youtube.com/channel/UCUhu_lKvaf5PCOmrD0Oq2tw",
                      "_blank",
                      "noopener,noreferrer"
                    )
                  }
                  id="youtube-button"
                  className="social-button"
                >
                  {" "}
                  <i class="fa fa-youtube fa-1x icon-3d"></i>{" "}
                </button>
              </div>
            </div>
          </StyledMenu>
        </div>
      </BrowserView>
      <MobileView>
        <div>
          <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
            {/* <div class="menuInfoMobile">
              <p className="menuInfoMobileText">
                {" "}
                -Use your mouse to look around
              </p>
            </div> */}
            {/* camera={{ fov: 70, position: [-30, 30, 20] }} */}
            <Canvas camera={{ fov: 70, position: [-55, 30, 20] }}>
              {/* <OrbitControls  /> */}
              <CameraControls />
              {/* <Stars factor={2} />
              <ambientLight intensity={0.5} />
              <spotLight position={[20, 20, 20]} angle={1} /> */}
              <OrbitControls makeDefault enableZoom={false} />
              <ambientLight />
              <Suspense fallback={null}>
                <Particles />
              </Suspense>
              {/* <MenuItemThree />
            <MenuItemThree /> */}
              {/* <Text color="white" fov={60} position={[120, 145, 120]} fontSize={55}>
              hello world!
            </Text> */}
              <fog attach="fog" args={["#202025", 0, 135]} />
              <Cloud
                count={3}
                radius={16.5}
                anchorX="center"
                anchorY="middle"
              />
              {/* <TrackballControls /> */}
            </Canvas>
          </StyledMenu>
        </div>
      </MobileView>
    </>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
