import React, { useEffect, useState, useRef, Component } from "react";
import { findDOMNode } from "react-dom";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import ReactPlayer from "react-player/lazy";
import TransitionsLong from "../Transition";
import Transitions from "../Transition";
import "../App.css";
import HeteroVideoMobile from "./HeteroVideoMobile";
import EarthLogo from "./EarthLogo";
import HeteroLogo from "./HeteroLogo";
import ExploreButton from "./Widgets/ExploreButton";

class HeteroVideo extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    url: "https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/Web%20V2.m4v?alt=media&token=f7f490bf-2f20-4ff1-9a0e-a42b76fb0e54",
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.0,
    muted: true,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    menuId: "main-menu",
    openMenu: false,
    isLoading: true,
    duration1: false,
    duration2: false,
    duration3: false,
    duration4: false,
    duration5: false,
    duration6: false,
    duration7: false,
    duration8: false,
    duration9: false,
    duration10: false,
    duration11: false,
    duration12: false,
    duration13: false,
    duration14: false,
    duration15: false,
  };

  componentDidMount() {
    console.log(" component mounut");
  }

  tiltEffect() {
    const tiltEls = document.querySelectorAll(".tilt");

    const tiltMove = (x, y) =>
      `perspective(1000px) scale(1.2) rotateX(${x}deg) rotateY(${y}deg)`;

    tiltEls.forEach((tilt) => {
      const height = tilt.clientHeight;
      const width = tilt.clientWidth;

      tilt.addEventListener("mousemove", (e) => {
        const x = e.layerX;
        const y = e.layerY;
        const multiplier = 8;

        const xRotate = multiplier * ((x - width / 2) / width);
        const yRotate = -multiplier * ((y - height / 2) / height);

        tilt.style.transform = tiltMove(yRotate, xRotate);
      });

      // tilt.addEventListener(
      //   "mouseout",
      //   () => (tilt.style.transform = tiltMove(0, 0))
      // );
    });
  }

  handleOpenMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
    if (!this.state.playing) {
      if (this.state.openMenu) {
        this.handlePlay();
      }
    } else {
      this.handlePlayPause();
    }
  };

  handleLoadedData = () => {
    setTimeout(() => this.setState({ isLoading: false }), 2200);
  };

  // useState(() => {
  //   console.log('here');
  // })

  load = (url) => {
    this.setState({
      url,
      played: 0,
      loaded: 0,
      pip: false,
    });
  };

  handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
    setTimeout(() => this.setState({ playbackRate: parseFloat("1.00") }), 200);
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  handleMakeSlow = () => {
    // this.setState({ playbackRate: parseFloat("0.50") });
    // setTimeout(() => this.handlePause(), 100);
  };

  handleTimeline = (e) => {
    // for (let index = 0; index < e; index++) {
    //   const element = array[index];
    //   this.setState({ duration15: false });
    // }
  };

  handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  handleOnPlaybackRateChange = (speed) => {
    this.setState({ playbackRate: parseFloat(speed) });
  };

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  handlePlay = () => {
    console.log("onPlay");
    this.setState({ playing: true });
    setTimeout(() => this.setState({ playbackRate: parseFloat("1.00") }), 200);
  };

  handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  handlePause = () => {
    console.log("onPause");
    this.setState({ playing: false });
  };

  handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  handleSeekChangeTime = (e) => {
    console.log("seek", e);
    this.setState({ played: parseFloat(e) });
  };

  handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
    if (this.state.playing == false) {
      this.setState({ playing: true });
    }
  };

  handleProgress = (state) => {
    console.log("onProgress", state);
    console.log(this.state.playedSeconds);

    let pSecond = parseFloat(this.state.playedSeconds);

    // if (pSecond > 1.5 && pSecond < 2.5) {
    //   //Pale Blue Dot , Our Home
    //   this.handleMakeSlow();
    // }
    if (pSecond > 0 && pSecond < 5.0) {
      // Our World Is Close To The End
      this.setState({ duration1: true });
      this.setState({ duration2: false });
      this.setState({ duration3: false });
      this.setState({ duration4: false });
      this.setState({ duration5: false });
      this.setState({ duration6: false });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 5.0) {
      // A Hope Emerge from skies
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: false });
      this.setState({ duration4: false });
      this.setState({ duration5: false });
      this.setState({ duration6: false });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 10.0) {
      //  Ready for the Journey
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: false });
      this.setState({ duration5: false });
      this.setState({ duration6: false });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 16.0) {
      //  Ready for the Journey
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: false });
      this.setState({ duration6: false });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 27.5) {
      // Reconfigure the habits
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: false });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 39) {
      // Self Questioning
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: false });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 44.5) {
      // The Truth of The Needs
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: false });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 62) {
      //Become a Transhuman
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: false });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 85.5) {
      //Ultra
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: false });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 111.5) {
      //Begin your journey
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: true });
      this.setState({ duration11: false });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 120.5) {
      //centralized universe
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: true });
      this.setState({ duration11: true });
      this.setState({ duration12: false });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 129.5) {
      //Book your seat
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: true });
      this.setState({ duration11: true });
      this.setState({ duration12: true });
      this.setState({ duration13: false });
      this.setState({ duration14: false });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    if (pSecond > 138.5) {
      //Earth 2.0
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: true });
      this.setState({ duration11: true });
      this.setState({ duration12: true });
      this.setState({ duration13: true });
      this.setState({ duration14: true });
      this.setState({ duration15: false });
      this.handleMakeSlow();
    }
    // if (pSecond > 163.5 && pSecond < 164.5) {
    //   //Beyond Market
    //   this.handleMakeSlow();
    // }
    if (pSecond > 160) {
      //Beyond Market
      this.setState({ duration1: true });
      this.setState({ duration2: true });
      this.setState({ duration3: true });
      this.setState({ duration4: true });
      this.setState({ duration5: true });
      this.setState({ duration6: true });
      this.setState({ duration7: true });
      this.setState({ duration8: true });
      this.setState({ duration9: true });
      this.setState({ duration10: true });
      this.setState({ duration11: true });
      this.setState({ duration12: true });
      this.setState({ duration13: true });
      this.setState({ duration14: true });
      this.setState({ duration15: true });
      console.log(" duration15" + this.state.duration15);
      this.handleMakeSlow();
    }
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  handleDuration = (duration) => {
    console.log("onDuration", duration);
    this.setState({ duration });
  };

  handleClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player))
  };

  renderLoadButton = (url, label) => {
    return <button onClick={() => this.load(url)}>{label}</button>;
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const {
      url,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      played,
      loaded,
      duration,
      playbackRate,
      pip,
      openMenu,
      isLoading,
      menuId,
      duration1,
      duration2,
      duration3,
      duration4,
      duration5,
      duration6,
      duration7,
      duration8,
      duration9,
      duration10,
      duration11,
      duration12,
      duration13,
      duration14,
      duration15,
    } = this.state;
    const SEPARATOR = " · ";

    return (
      <>
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>My Title</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet> */}
        <ThemeProvider theme={theme}>
          <BrowserView>
            <TransitionsLong>
              <EarthLogo />
              <HeteroLogo />
              <div className="app noselect">
                {/* <progress className="timeline" max={1} value={played}  /> */}
                <ul id="vertical-timeline">
                  <li className="major-duration-1">
                    <div class="input-group-custom">
                      <input
                        className="marker-dune marker"
                        value="0.0"
                        onMouseDown={this.handleSeekMouseDown}
                        onChange={this.handleSeekChange}
                        onMouseUp={this.handleSeekMouseUp}
                      ></input>
                      <h6 className="marker-1-text">DUNE</h6>
                    </div>
                    {/* <div class="contentMarker">
      EARTH
    </div> */}
                    {duration2 ? (
                      <div className="vertical-progress-minor-1"></div>
                    ) : (
                      <div className="vertical-progress-minor-1-grey"></div>
                    )}

                    <li className="minor-duration-1">
                      {" "}
                      {duration2 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="5.5"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="5.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration3 ? (
                      <div className="vertical-progress-minor-1"></div>
                    ) : (
                      <div className="vertical-progress-minor-1-grey"></div>
                    )}
                    <li className="minor-duration-2">
                      {" "}
                      {duration3 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="10.5"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="10.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration4 ? (
                      <div className="vertical-progress-minor-2"></div>
                    ) : (
                      <div className="vertical-progress-minor-2-grey"></div>
                    )}
                  </li>
                  <li className="major-duration-3">
                    {duration4 ? (
                      <div class="input-group-custom">
                        <input
                          className="marker-tunel-focus marker"
                          value="16.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>TUNNEL</h6>
                      </div>
                    ) : (
                      <div class="input-group-custom">
                        <input
                          className="marker-tunel marker"
                          value="16.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>TUNNEL</h6>
                      </div>
                    )}

                    {duration5 ? (
                      <div className="vertical-progress-minor-3"></div>
                    ) : (
                      <div className="vertical-progress-minor-3-grey"></div>
                    )}
                    <li className="minor-duration-2">
                      {" "}
                      {duration5 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="28.0"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="28.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration6 ? (
                      <div className="vertical-progress-minor-4"></div>
                    ) : (
                      <div className="vertical-progress-minor-4-grey"></div>
                    )}
                    <li className="minor-duration-2">
                      {" "}
                      {duration6 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="39.5"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="39.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration7 ? (
                      <div className="vertical-progress-minor-5"></div>
                    ) : (
                      <div className="vertical-progress-minor-5-grey"></div>
                    )}
                  </li>
                  <li className="major-duration-3">
                    {duration7 ? (
                      <div class="input-group-custom">
                        <input
                          className="marker-asteroid-focus marker"
                          value="45.0"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>HETEROMETA</h6>
                      </div>
                    ) : (
                      <div class="input-group-custom">
                        <input
                          className="marker-asteroid marker"
                          value="45.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>HETEROMETA</h6>
                      </div>
                    )}

                    {duration8 ? (
                      <div className="vertical-progress-minor-8"></div>
                    ) : (
                      <div className="vertical-progress-minor-8-grey"></div>
                    )}
                    <li className="minor-duration-3">
                      {" "}
                      {duration8 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="62.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="62.5"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration9 ? (
                      <div className="vertical-progress-minor-9"></div>
                    ) : (
                      <div className="vertical-progress-minor-9-grey"></div>
                    )}
                    <li className="minor-duration-3">
                      {" "}
                      {duration9 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="86.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="86.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}
                    </li>
                    {duration10 ? (
                      <div className="vertical-progress-minor-10"></div>
                    ) : (
                      <div className="vertical-progress-minor-10-grey"></div>
                    )}
                  </li>
                  <li className="major-duration-4">
                    {duration10 ? (
                      <div class="input-group-custom">
                        <input
                          className="marker-matrix-focus marker"
                          value="112.0"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>EARTH 2.0</h6>
                      </div>
                    ) : (
                      <div class="input-group-custom">
                        <input
                          className="marker-matrix marker"
                          value="112.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>EARTH 2.0</h6>
                      </div>
                    )}

                    {duration11 ? (
                      <div className="vertical-progress-minor-8"></div>
                    ) : (
                      <div className="vertical-progress-minor-8-grey"></div>
                    )}
                    <li className="minor-duration-3">
                      {duration11 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="121.0"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="121.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}{" "}
                    </li>
                    {duration12 ? (
                      <div className="vertical-progress-minor-9"></div>
                    ) : (
                      <div className="vertical-progress-minor-9-grey"></div>
                    )}
                    <li className="minor-duration-3">
                      {" "}
                      {duration12 ? (
                        <input
                          className="minor-marker-focus minor"
                          value="121.0"
                          autoFocus
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      ) : (
                        <input
                          className="minor-marker-1 minor"
                          value="121.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                      )}{" "}
                    </li>
                    {duration13 ? (
                      <div className="vertical-progress-minor-10"></div>
                    ) : (
                      <div className="vertical-progress-minor-10-grey"></div>
                    )}
                    {duration13 ? (
                      <div class="input-group-custom">
                        <input
                          className="marker-beyondmarket-focus marker"
                          value="139.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>BEYOND MARKET</h6>
                      </div>
                    ) : (
                      <div class="input-group-custom">
                        <input
                          className="marker-beyondmarket marker"
                          value="139.0"
                          onMouseDown={this.handleSeekMouseDown}
                          onChange={this.handleSeekChange}
                          onMouseUp={this.handleSeekMouseUp}
                        />
                        <h6>BEYOND MARKET</h6>
                      </div>
                    )}
                  </li>
                  {/* <li className="major-duration-4">
      <input
        className="marker-matrix marker"
        value="130.0"
        onMouseDown={this.handleSeekMouseDown}
        onChange={this.handleSeekChange}
        onMouseUp={this.handleSeekMouseUp}
      />
      <div class="contentMarker">EARTH 2.0</div>
      {duration11 ? (
        <div className="vertical-progress-minor-11"></div>
      ) : (
        <div className="vertical-progress-minor-11-grey"></div>
      )}
      <li className="minor-duration-4">
        {" "}
        <input
          className="minor-marker-4 minor"
          value="137.0"
          onMouseDown={this.handleSeekMouseDown}
          onChange={this.handleSeekChange}
          onMouseUp={this.handleSeekMouseUp}
        />
      </li>
      {duration12 ? (
        <div className="vertical-progress-minor-12"></div>
      ) : (
        <div className="vertical-progress-minor-12-grey"></div>
      )}
      <li className="minor-duration-4">
        {" "}
        <input
          className="minor-marker-4 minor"
          value="152.0"
          onMouseDown={this.handleSeekMouseDown}
          onChange={this.handleSeekChange}
          onMouseUp={this.handleSeekMouseUp}
        />
      </li>
    </li>
    {duration13 ? (
      <div className="vertical-progress-minor-13"></div>
    ) : (
      <div className="vertical-progress-minor-13-grey"></div>
    )}
    <li className="major-duration-2">
      <input
        className="marker-yuvarlak marker"
        value="158.0"
        onMouseDown={this.handleSeekMouseDown}
        onChange={this.handleSeekChange}
        onMouseUp={this.handleSeekMouseUp}
      />
      <div class="contentMarker">BEYONDMARKET</div>
      {duration14 ? (
        <div className="vertical-progress-minor-14"></div>
      ) : (
        <div className="vertical-progress-minor-14-grey"></div>
      )}
      <li className="minor-duration-2">
        {" "}
        <input
          className="minor-marker-2 minor"
          value="160.0"
          onMouseDown={this.handleSeekMouseDown}
          onChange={this.handleSeekChange}
          onMouseUp={this.handleSeekMouseUp}
        />
      </li>
      {duration14 ? (
        <div className="vertical-progress-minor-14"></div>
      ) : (
        <div className="vertical-progress-minor-14-grey"></div>
      )}
      <li className="minor-duration-2">
        {" "}
        <input
          className="minor-marker-2 minor"
          value="160.0"
          onMouseDown={this.handleSeekMouseDown}
          onChange={this.handleSeekChange}
          onMouseUp={this.handleSeekMouseUp}
        />
      </li>
    </li>
    {duration15 ? (
      <div className="vertical-progress-minor-15"></div>
    ) : (
      <div className="vertical-progress-minor-15-grey"></div>
    )}
    <li className="major-duration-2">
      <input
        className="marker-heteroemta marker"
        value="160.0"
        onMouseDown={this.handleSeekMouseDown}
        onChange={this.handleSeekChange}
        onMouseUp={this.handleSeekMouseUp}
      />
      <div class="contentMarker">EXPLORE</div>
    </li> */}
                </ul>
                {/* <ExploreButton/> */}
                {duration15 ? (
                  <div>
                    <ExploreButton />
                  </div>
                ) : (
                  <div></div>
                )}
                {playing ? (
                  <>
                    <div
                      className="video-container"
                      onClick={this.handlePlayPause}
                    >
                      <ReactPlayer
                        ref={this.ref}
                        className="video-background-react-player"
                        width="100%"
                        height="100%"
                        url={url}
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onReady={this.tiltEffect}
                        onStart={this.tiltEffect}
                        onPlay={this.handlePlay}
                        onEnablePIP={this.handleEnablePIP}
                        onDisablePIP={this.handleDisablePIP}
                        onPause={this.handlePause}
                        onBuffer={() => console.log("onBuffer")}
                        onPlaybackRateChange={this.handleOnPlaybackRateChange}
                        onSeek={(e) => console.log("onSeek", e)}
                        onEnded={this.handleEnded}
                        onError={(e) => console.log("onError", e)}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                        onLoadedData={this.handleLoadedData}
                      />
                    </div>
                    <div className="top-center-continue-text">
                      {/* <img className="img-tap-to-continue" src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/public%2FTap%20to%20Continue%20(3).png?alt=media&token=45d8b6e2-f80d-45c6-9caf-6ab4771da120" alt="Rotate"></img> */}
                      <h1 className="animate-flicker">Tap To Read</h1>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="video-container-stop"
                      onClick={this.handlePlayPause}
                    >
                      <ReactPlayer
                        ref={this.ref}
                        className="video-background-react-player"
                        width="100%"
                        height="100%"
                        url={url}
                        pip={pip}
                        playing={playing}
                        controls={controls}
                        light={light}
                        loop={loop}
                        playbackRate={playbackRate}
                        volume={volume}
                        muted={muted}
                        onReady={this.tiltEffect}
                        onStart={this.tiltEffect}
                        onPlay={this.handlePlay}
                        onEnablePIP={this.handleEnablePIP}
                        onDisablePIP={this.handleDisablePIP}
                        onPause={this.handlePause}
                        onBuffer={() => console.log("onBuffer")}
                        onPlaybackRateChange={this.handleOnPlaybackRateChange}
                        onSeek={(e) => console.log("onSeek", e)}
                        onEnded={this.handleEnded}
                        onError={(e) => console.log("onError", e)}
                        onProgress={this.handleProgress}
                        onDuration={this.handleDuration}
                        onLoadedData={this.handleLoadedData}
                      />
                    </div>
                    <div className="top-center-continue-text">
                      {/* <img className="img-tap-to-continue" src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/public%2FTap%20to%20Continue%20(3).png?alt=media&token=45d8b6e2-f80d-45c6-9caf-6ab4771da120" alt="Rotate"></img> */}
                      <h1 className="animate-flicker">Tap To Continue</h1>
                    </div>

                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </>
                )}
              </div>
              <div>
                <FocusLock disabled={!openMenu}>
                  <Burger
                    open={openMenu}
                    setOpen={this.handleOpenMenu}
                    aria-controls={menuId}
                  />
                  <Menu
                    open={openMenu}
                    setOpen={this.handleOpenMenu}
                    id={menuId}
                  />
                </FocusLock>
              </div>
            </TransitionsLong>
          </BrowserView>
          <MobileView>
            <HeteroVideoMobile></HeteroVideoMobile>
          </MobileView>
        </ThemeProvider>
      </>
    );
  }
}

export { HeteroVideo };
