import "../../App.css";
import React, { useEffect, useState, useRef } from "react";
import Burger from "../Burger/Burger";
import Menu from "../Menu/Menu";
import FocusLock from "react-focus-lock";
import { theme } from "../../theme";
import { ThemeProvider } from "styled-components";
import { BrowserView, MobileView } from "react-device-detect";
import Footer from "../Footer";
import { Container, Row, Col } from "react-grid-system";
import HeteroLogo from "../HeteroLogo";

function WhyHeteroMeta() {
  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";
  const [isLoading, setLoading] = useState(true);

  useState(() => {
    setTimeout(function () {
      setLoading(false);
    }, 600);
  });

  return (
    <ThemeProvider theme={theme}>
      <MobileView>
        {/*  ------------- Loading -------------- */}
        {isLoading ? (
          <div id="loading">
            <img id="loadingGifMobile" src="loading.gif" />
          </div>
        ) : (
          <>
<HeteroLogo/>            
            <Container className="container">
              {/* Title */}
              <p className="titleText">
                <i class="fa fa-arrow-left backTitle"></i>
                <a className="backTitle" href="/discover">
                  Back{" "}
                </a>
              </p>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Why HeteroMeta
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
              </h1>

              <div class="card text-white bg-transparent">
                <br></br>
                <br></br>
                <br></br>
                <img
                  className="cardImageContent"
                  src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2Fmobile%2Fdiscover_why%20heterometa(mobile).png?alt=media&token=ac3ca600-0f50-460c-b7e3-a24379154e36"
                  alt="Card image cap"
                ></img>
                <div class="card-body">
                  <br></br>
                  <br></br>
                  <p class="card-text cardContent">
                    Note from the whitepaper, ‘’Why do we buy something? What is
                    it we need? Is it the function of the product? Maybe. But is
                    that the only factor? No. This is not the case today. In the
                    past, it was ahead of brands. Honestly, think about the last
                    thing you bought just for its function. So, why do we buy
                    something if we don't buy it just for its function? Of
                    course, it's not just because we like its look, design, and
                    feel, because we know it will get old. Everything that
                    affects us will fade in time. And even if we are meticulous
                    users, we also know that manufacturers will constantly
                    release new versions in order to be able to sell again, so
                    the product we buy will have to get old. Everything new is
                    old. For example, humanity buys an average of 1.5 billion
                    smartphones every year. Since not all of these people got
                    their first phone. Think of the huge smartphone junk created
                    by old phones. Spread that across all technology, even all
                    other industries. Think of the huge garbage that has formed,
                    and the damage to our world from the waste of energy and raw
                    materials spent on the production of a new one. Think of the
                    crises, the famine, the state of nature, and humanity in the
                    no-change scenario.’’
                  </p>
                  <br></br>
                  <p class="card-text cardContent">
                    It is our duty to always think and question the way we
                    thouch to our world and its cycle. Metaverse and HeteroMeta
                    provides us to work with this versitile virtual environment
                    to alter ourself alough with using blockchain as a
                    foundation.
                  </p>
                </div>
              </div>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <Footer></Footer>
            </Container>
          </>
        )}
      </MobileView>
      <BrowserView>
        {/*  ------------- Loading -------------- */}
        {isLoading ? (
          <div id="loading">
            <img id="loadingGif" src="../loading.gif" />
          </div>
        ) : (
          <>
            <HeteroLogo/>
            <Container className="container">
              {/* Title */}
              <p className="titleText">
                <i class="fa fa-arrow-left backTitle"></i>
                <a className="backTitle" href="/discover">
                  Back{" "}
                </a>
              </p>
              <h1 class="jt --debug">
                <span class="jt__row">
                  <span class="jt__text">
                    {" "}
                    Why HeteroMeta
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                  </span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
                <span class="jt__row jt__row--sibling" aria-hidden="true">
                  <span class="jt__text"> Why HeteroMeta</span>
                </span>
              </h1>

              <div class="card text-white bg-transparent">
                <br></br>
                <br></br>
                <br></br>
                <img
                  className="cardImageContent"
                  src="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/discover%2FDiscover_why%20heterometa.jpg?alt=media&token=23ea50b1-ed7c-44bd-9ba8-2d1303807f15Card image cap"
                ></img>
                <div class="card-body">
                  <br></br>
                  <br></br>
                  <p class="card-text cardContent">
                    Note from the whitepaper, ‘’Why do we buy something? What is
                    it we need? Is it the function of the product? Maybe. But is
                    that the only factor? No. This is not the case today. In the
                    past, it was ahead of brands. Honestly, think about the last
                    thing you bought just for its function. So, why do we buy
                    something if we don't buy it just for its function? Of
                    course, it's not just because we like its look, design, and
                    feel, because we know it will get old. Everything that
                    affects us will fade in time. And even if we are meticulous
                    users, we also know that manufacturers will constantly
                    release new versions in order to be able to sell again, so
                    the product we buy will have to get old. Everything new is
                    old. For example, humanity buys an average of 1.5 billion
                    smartphones every year. Since not all of these people got
                    their first phone. Think of the huge smartphone junk created
                    by old phones. Spread that across all technology, even all
                    other industries. Think of the huge garbage that has formed,
                    and the damage to our world from the waste of energy and raw
                    materials spent on the production of a new one. Think of the
                    crises, the famine, the state of nature, and humanity in the
                    no-change scenario.’’
                  </p>
                  <br></br>
                  <p class="card-text cardContent">
                    It is our duty to always think and question the way we
                    thouch to our world and its cycle. Metaverse and HeteroMeta
                    provides us to work with this versitile virtual environment
                    to alter ourself alough with using blockchain as a
                    foundation.
                  </p>
                </div>
              </div>

              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <div class="firefly"></div>
              <Footer></Footer>
            </Container>
          </>
        )}
      </BrowserView>

      <div ref={node}>
        <FocusLock disabled={!open}>
          <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
          <Menu open={open} setOpen={setOpen} id={menuId} />
        </FocusLock>
      </div>
    </ThemeProvider>
  );
}

export { WhyHeteroMeta };
