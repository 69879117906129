import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import Transitions from "../Transition";
import FocusLock from "react-focus-lock";
import Burger from "./Burger/Burger";
import Menu from "./Menu/Menu";
import "../App.css";
import ReactPlayer from "react-player/lazy";
import HeteroLogo from "./HeteroLogo";
// const useStyles = createUseStyles(style);

// const lerp = (a, b, n) => (1 - n) * a + n * b;
// const clamp = (value, min, max) => Math.min(Math.max(value, min), max);

const HeteroVideoMobile = () => {
  //--------- Loading --------- \\
  const [isLoading, setLoading] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  //* ---------------------------------- */

  const onLoadedData = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1200);
  };

  // -- Burger Menu -- \\
  const [open, setOpen] = useState(false);
  const node = useRef();
  const menuId = "main-menu";

  function handlePlayPause() {
    setPlaying(!playing);
  }

  function handleOpenMenu() {
    setOpenMenu(!openMenu);
    if (!playing) {
      if (openMenu) {
        handlePlay();
      }
    } else {
      handlePlayPause();
    }
  }

  function handlePlay() {
    // this.setState({ playing: true });
    // setTimeout(() => this.setState({ playbackRate: parseFloat("1.00") }), 200);
    setPlaying(true);
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Transitions>
          {isLoading ? (
            <div id="loading">
              <img id="loadingGifMobile" src="loading.gif" />
            </div>
          ) : (
            <div></div>
          )}
          <HeteroLogo></HeteroLogo>
          {playing ? (
            <div className="video-container" onClick={handlePlayPause}>
              <ReactPlayer
                // controls
                autoplay="true"
                playsinline
                playing={playing}
                className="video-background-react-player-mobile"
                width="100%"
                height="100%"
                url="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/Mobile%20V2%20Rev.m4v?alt=media&token=2f2292e6-4702-4d3d-86b1-f2d40076ea9b"
                muted={true}
              />
            </div>
          ) : (
            <div className="video-container-stop" onClick={handlePlayPause}>
              <ReactPlayer
                // controls
                autoplay="true"
                playsinline
                playing={playing}
                className="video-background-react-player-mobile"
                width="100%"
                height="100%"
                url="https://firebasestorage.googleapis.com/v0/b/heterometadev-4d7aa.appspot.com/o/Mobile%20V2%20Rev.m4v?alt=media&token=2f2292e6-4702-4d3d-86b1-f2d40076ea9b"
                muted={true}
              />
              <div className="top-center-continue-text">
                <h1 className="animate-flicker">Tap To Continue</h1>
              </div>
            </div>
          )}
        </Transitions>
        <div ref={node}>
          <FocusLock disabled={!open}>
            {/* <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
            <Menu open={open} setOpen={setOpen} id={menuId} /> */}

            <Burger
              open={openMenu}
              setOpen={handleOpenMenu}
              aria-controls={menuId}
            />
            <Menu open={openMenu} setOpen={handleOpenMenu} id={menuId} />
          </FocusLock>
        </div>
      </>
    </ThemeProvider>
  );
};

export default HeteroVideoMobile;
