import styled from 'styled-components';


export const StyledBurger = styled.button`
position: fixed;
top: 3%;
left: 2rem;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 2.0rem;
height: 2.0rem;
background: transparent;
border: none;
cursor: pointer;
padding: 0;
z-index: 13;

@media (max-width: 768px) {
    /* position: absolute; */
    top: 2.5%;
    left: 1.5rem;
    width: 2rem;
    height: 2rem;
  }

  @media (min-width: 2500px) {
    /* position: absolute; */
    top: 2.5%;
    left: 1.5rem;
    width: 3rem;
    height: 3rem;
  }
span {
  width: 2.0rem;
  height: 0.25rem;
  background: ${({ theme, open }) => open ? theme.primaryLight : theme.primaryLight};
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
  
  :first-child {
    transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
  }
  :nth-child(2) {
    opacity: ${({ open }) => open ? '0' : '1'};
    transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
  }
  :nth-child(3) {
    transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
  }
  @media (max-width: 768px) {
    width: 2.0rem;
    height: 0.25rem;
    background: ${({ theme, open }) => open ? theme.primaryLight : theme.primaryLight};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  @media (min-width:2500px) {
    width: 3rem;
    height:0.30rem;
    background: ${({ theme, open }) => open ? theme.primaryLight : theme.primaryLight};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
`;
